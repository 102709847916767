
import TataLink from "~/components/global-components/tata-link";
export default {
  components: { TataLink },
  props: {
    showMenu: {
      type: Boolean,
      default: false
    },
    menuData: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    contactUs: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    }
  },
  data: () => {
    return {
      active_el: null,
      remove_el: null
    };
  },
  watch: {
    $route() {
      this.active_el = null;
    },
    showMenu() {
      this.active_el = null;
    }
  },
  mounted() {},
  methods: {
    deactivate: function () {
      this.active_el = null;
    },
    activate: function (el) {
      this.active_el = el;
    }
  }
};
