
  export default {
    components: {},
    data: () => {
      return {
        activeIndex: 1,
        isActive: false
      };
    },
    mounted() {},
    methods: {
        changeTab(index) {
            this.activeIndex = index;
            this.isActive = !this.isActive;
        }
    }
  };
  