
export default {
  data() {
    return {
      activeIndex: 0
    };
  },
  methods: {
    openPopup(index) {
      this.$refs["leaders_popup"].show();
      this.activeIndex = index;
    }
  }
};
