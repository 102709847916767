
export default {
  data() {
    return {
      formData: {
        form_type: "form1",
        form1_section: { thank_you_message: "Thank you" },
        eventData: {
          event: "TataFormSubmit",
          category: "subscribe form",
          action: "Subscribe Form Submit",
          label: this.$attrs.title
        }
      }
    };
  },
  mounted() {},
  created() {}
};
