
export default {
  data: () => {
    return {
      activeTab: 0,
      prg: 0,
      coloredLines: [],
      scrollMagicScene: null,
      transitionName: ""
    };
  },
  beforeDestroy() {
    if (this.scrollMagicScene) {
      this.scrollMagicScene = null;
    }
  },
  mounted() {
    let __vm = this;
    window.addEventListener("wheel", function (event) {
      if (event.deltaY < 0) {
        __vm.transitionName = "fade-in-up";
      } else if (event.deltaY > 0) {
        __vm.transitionName = "fade-in-down";
      }
    });
    this.coloredLines = document.getElementsByClassName(
      "desktop-scroll-section-trigger-line-colored"
    );
    if (
      window.screen.width > 1023 &&
      document.getElementsByClassName("_tata__sticky-tabs").length > 0
    ) {
      this.scrollMagicScene = this.$scrollmagic
        .scene({
          // Duration of animation
          duration: 1200,
          offset: 250,
          triggerElement: "._tata__sticky-tabs"
        })
        .setPin(`#sticky-trigger`)
        .on("progress", (e) => {
          if (e.progress * 100 < 33.33) {
            this.activeTab = 0;
            this.prg = e.progress;
            let maths = e.progress * 100;
            maths = maths / 33.33;
            maths = maths * 100;
            if (this.coloredLines.length) {
              this.coloredLines[1].style.height = 0 + "%";
              this.coloredLines[2].style.height = 0 + "%";
              this.coloredLines[0].style.height = maths + "%";
            }
          } else if (e.progress * 100 < 66.66) {
            this.activeTab = 1;
            this.prg = e.progress;
            let maths = e.progress * 100 - 33.33;
            maths = maths / 33.33;
            maths = maths * 100;
            if (this.coloredLines.length) {
              this.coloredLines[0].style.height = 0 + "%";
              this.coloredLines[2].style.height = 0 + "%";
              this.coloredLines[1].style.height = maths + "%";
            }
          } else if (e.progress * 100 < 99.99) {
            this.activeTab = 2;
            this.prg = e.progress;
            let maths = e.progress * 100 - 66.66;
            maths = maths / 33.33;
            maths = maths * 100;
            if (this.coloredLines.length) {
              this.coloredLines[0].style.height = 0 + "%";
              this.coloredLines[1].style.height = 0 + "%";
              this.coloredLines[2].style.height = maths + "%";
            }
          }
        });
      this.$scrollmagic.addScene(this.scrollMagicScene);
    }
  },
  methods: {
    changeClass(index) {
      this.activeTab = index;
      if (index == 0) {
        this.coloredLines[1].style.height = 0 + "%";
        this.coloredLines[2].style.height = 0 + "%";
        this.coloredLines[0].style.height = "100%";
      } else if (index == 1) {
        this.coloredLines[0].style.height = 0 + "%";
        this.coloredLines[2].style.height = 0 + "%";
        this.coloredLines[1].style.height = "100%";
      } else {
        this.coloredLines[0].style.height = 0 + "%";
        this.coloredLines[1].style.height = 0 + "%";
        this.coloredLines[2].style.height = "100%";
      }
    }
  }
};
