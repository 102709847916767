
    export default {
        data(){
            return{
                relatedSolSwiper:null,
            }
        },
        mounted(){
            this.relatedSolSwiper = new Swiper('.relatedSolutionSwiper', {
                slidesPerView: 3,
                spaceBetween: 30,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    300: {
                        centeredSlides: true,
                        initialSlide: 0,
                        slidesPerView: 1.2,
                        spaceBetween: 15,
                        loop:true,
                        pagination: {
                            el: ".swiper-pagination",
                        },
                    },
                    768: {
                        // centeredSlides: true,
                        slidesPerView: 2.2,
                        spaceBetween: 20,
                        loop:false
                    },
                    992:{
                        slidesPerView: 3,
                        spaceBetween: 30,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        pagination: {
                            el: ".swiper-pagination",
                        }, 
                    }
                },
            });
        },
    }
