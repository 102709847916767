export default function ({ route, store, redirect }) {
  const { path, query, hash } = route;
  const queryString = Object.keys(query)
    .map((key) => key + "=" + query[key])
    .join("&");

  if (store.state.redirects.length) {
    store.state.redirects.forEach((e) => {
      if (e.enabled) {
        let action_url = "";
        if (e.match_type === "url") {
          action_url = e.action_data.url;
        } else if (e.match_type === "server") {
          action_url = e.action_data.url_from;
        } else {
          action_url = process.env.FRONTEND_URL;
        }
        if (e.regex) {
          let reg = new RegExp(e.url, "gi");
          if (reg.test(path)) {
            let match = path.match(e.url);
            for (let i = 1; i <= match.length - 1; i++) {
              action_url = action_url.replace("$" + i, match[i]);
            }
            let redirect_url =
              action_url.indexOf("?") > -1
                ? `${action_url}&${queryString}`
                : `${action_url}?${queryString}`;
            redirect(redirect_url);
          }
        } else {
          if (e.url === path) {
            let redirect_url =
              action_url.indexOf("?") > -1
                ? `${action_url}&${queryString}`
                : `${action_url}?${queryString}`;
            redirect(redirect_url);
          }
        }
      }
    });
  }
  //Clear all headers element
  store.dispatch("l1/updateItemSpecificData", null);
  store.dispatch("l1/updatePageTitle", "");
  store.commit("l1/updateBreadcrumbs", null);
  store.commit("resource/updateFeaturedResource", null);
  if (path !== "/" && !path.endsWith("/")) {
    const nextPath = path + "/";
    const nextRoute = { path: nextPath, query, hash };

    redirect(nextRoute);
  }
}
