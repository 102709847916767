
import { Glide, GlideSlide } from "vue-glide-js";
export default {
    components: {
        [Glide.name]: Glide,
        [GlideSlide.name]: GlideSlide
    },
    data() {
        return {
            selectedTab: 'industry',
            isMoveCard: true,
            options: {
                type: "carousel",
                perView: 2,
                focusAt: 0,
                startAt: 0,
                gap: 30
            },
            tabIndex: 0,
            timeline: undefined,
            scrollTrigger: undefined,
            isMobile: false,
            activeItem: 0,
            isReady: true,
            solutionSwiper: null,
        };
    },

    created() {
        // Set the initial tab based on the attribute if provided
        this.selectedTab = this.$attrs.select_active_tab || 'industry';
    },

    methods: {
        selectTab(tabName) {
            this.selectedTab = tabName;
        },

        initSolutionSwiper() {
            // Check if swiper is available globallyy
            if (typeof Swiper !== 'undefined') {
                // Initializing swiper
                this.solutionSwiper = new Swiper(".solutionSwiper", {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                });
            }
        },

        animateOnScroll() {
            let tabLinks = document.querySelectorAll(".nav-item");
            let tabContents = document.querySelectorAll(".tab-pane");

            function activeTabSwiwtcher(value) {
                tabLinks.forEach((tabLink) => {
                    tabLink
                        .getElementsByTagName("a")[0]
                        .setAttribute("aria-selected", "false");
                    tabLink.getElementsByTagName("a")[0].classList.remove("active");
                });
                tabLinks[value]
                    .getElementsByTagName("a")[0]
                    .setAttribute("aria-selected", "false");
                tabLinks[value].getElementsByTagName("a")[0].classList.add("active");

                tabContents.forEach((tabContent) => {
                    tabContent.setAttribute("aria-hidden", "true");
                    tabContent.classList.remove("active");
                    tabContent.style.display = "none";
                });
                tabContents[value].setAttribute("aria-hidden", "false");
                tabContents[value].classList.add("active");
                tabContents[value].style.display = "block";
            }
        },
        handleMobileAccordion(id) {
            if (id != this.activeItem) {
                this.activeItem = id;
            } else {
                this.activeItem = null;
            }
        },
        reInitSlider() {
            this.isReady = false;
            this.$nextTick(() => {
                this.isReady = true;
            });
        },
        enableMobile() {
            this.isMobile = window.innerWidth <= 768;
        }
    },

    mounted() {
        this.selectedTab = this.$attrs.select_active_tab;

        if (this.selectedTab === 'platform') {
            this.initSolutionSwiper();
        }

        // Adding a watch for changes in the selectedTab
        this.$watch('selectedTab', (newTab, oldTab) => {
            if (newTab === 'platform' && oldTab !== 'platform') {
                this.initSolutionSwiper();
            }
        });
        this.enableMobile(); // Call on mount to set the device type
        window.addEventListener('resize', this.enableMobile);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.enableMobile);
    },

}
