
export default {
  data() {
    return {
      current_map: false
    };
  },
  computed: {},
  mounted() {
    for (let ele in this.$attrs.network_links) {
      if (this.$attrs.network_links[ele].child_links.length) {
        for (let e in this.$attrs.network_links[ele].child_links) {
          if (
            this.$attrs.network_links[ele].child_links[e].grand_child.length
          ) {
            for (let element in this.$attrs.network_links[ele].child_links[e]
              .grand_child) {
              if (element == 0) {
                this.current_map = this.$attrs.network_links[ele].child_links[
                  e
                ].grand_child[element].pdf.url;
                break;
              }
            }
          } else {
            if (e === 0 && !this.current_map) {
              this.current_map = this.$attrs.network_links[ele].child_links[
                e
              ].pdf.url;
              break;
            }
          }
        }
      }
    }
  },
  methods: {
    OpenMap(pdf) {
      this.current_map = pdf;
    }
  }
};
