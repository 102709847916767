
    export default{
        data(){
            return{
                nudgeData:{
                    pageURL:null,
                    trackerEventName:null,
                },
            }
        },
        computed: {
            computedNudgeData() {
                return {
                    pageURL: this.$attrs.page_url,
                    trackerEventName: this.$attrs.tracker_event_name,
                };
            },
        },
        beforeMount() {
            this.nudgeData = this.computedNudgeData;
        },
    }
