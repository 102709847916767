export const state = () => ({
  menu: []
});
export const mutations = {
  getMenuData(state, data) {
    state.menu = data;
  }
};
export const actions = {
  async updateMenuData({ commit }) {
    try {
      const res = await this.$axios.get(`menu`, {
        useCache: true
      });
      commit("getMenuData", res);
      return res;
    } catch (err) {
      console.error(err);
    }
  }
};
