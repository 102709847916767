
export default {
  data: () => {
    return {
      activeIndex: 0,
      activeTabIndex: 0,
      visible: false,
      active: false,
      isActive: false
    };
  },
  computed: {
    activeTabTitle() {
      const activeTab = this.$attrs.tabs[this.activeIndex];
      return activeTab ? activeTab.tab_title : "";
    }
  },
  methods: {
    changeTab(index) {
      this.activeIndex = index;
    },

    changeTabMob(index) {
      this.activeIndex = index;
      var mobile = document.getElementsByClassName("mobLayout")[0].offsetTop;
      var headerheight =
        document.getElementsByClassName("_tata__header")[0].offsetHeight + 50;
      var offsetPosition = mobile - headerheight;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    },

    setActiveTab(index) {
      this.activeIndex = index;
    },
    toggleActive() {
      this.isActive = !this.isActive;
    },
    removeActiveClass() {
      this.isActive = false;
    }
  }
};
