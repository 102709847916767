export const section25 = () => {
  return {
    focusAt: "center",
    perView: 1.2,
    type: "carousel",
    bullet: false,
    gap: 20,
    animationTimingFunc: "ease-in-out"
  };
};
