
export default {
  props: {
    image: {
      type: Object,
      default: null
    }
  },
  computed: {
    computedImage() {
      return this.image;
    }
  }
};
