
export default {
  data() {
    return {
      activeTabIndex: 0
    };
  },
  methods: {
    changeActiveTab(index) {
      this.activeTabIndex = index;
    }
  }
};
