
import { Glide, GlideSlide } from "vue-glide-js";
import { pressReleaseCarousel } from "~/assets/sliderConfig/pressReleaseCarousel";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      pressReleaseCarousel: pressReleaseCarousel(),
      tabData: [
        {
          tabTitle: "tab 1",
          content: [
            {
              title: "tab 1 title 1",
              desc: "tab 1 description 1",
              link: "https://www.youtube.com/watch?v=xuYRglBC-Vs&ab_channel=ColorMusic",
              img: "https://placehold.it/300"
            },
            {
              title: "tab 1 title 2",
              desc: "tab 1 description 2",
              link: "https://www.youtube.com/watch?v=xuYRglBC-Vs&ab_channel=ColorMusic",
              img: "https://placehold.it/400"
            },
            {
              title: "tab 1 title 3",
              desc: "tab 1 description 3",
              link: "https://www.youtube.com/watch?v=xuYRglBC-Vs&ab_channel=ColorMusic",
              img: "https://placehold.it/500"
            }
          ]
        },
        {
          tabTitle: "tab 2",
          content: [
            {
              title: "tab 2 title 1",
              desc: "tab 2 description 1",
              link: "https://www.youtube.com/watch?v=xuYRglBC-Vs&ab_channel=ColorMusic",
              img: "https://placehold.it/300"
            },
            {
              title: "tab 2 title 2",
              desc: "tab 2 description 2",
              link: "https://www.youtube.com/watch?v=xuYRglBC-Vs&ab_channel=ColorMusic",
              img: "https://placehold.it/400"
            },
            {
              title: "tab 2 title 3",
              desc: "tab 2 description 3",
              link: "https://www.youtube.com/watch?v=xuYRglBC-Vs&ab_channel=ColorMusic",
              img: "https://placehold.it/500"
            }
          ]
        },
        {
          tabTitle: "tab 3",
          img: "https://placehold.it/300",
          content: [
            {
              title: "tab 3 title 1",
              desc: "tab 3 description 1",
              link: "https://www.youtube.com/watch?v=xuYRglBC-Vs&ab_channel=ColorMusic",
              img: "https://placehold.it/300"
            },
            {
              title: "tab 3 title 2",
              desc: "tab 3 description 2",
              link: "https://www.youtube.com/watch?v=xuYRglBC-Vs&ab_channel=ColorMusic",
              img: "https://placehold.it/400"
            },
            {
              title: "tab 3 title 3",
              desc: "tab 3 description 3",
              link: "https://www.youtube.com/watch?v=xuYRglBC-Vs&ab_channel=ColorMusic",
              img: "https://placehold.it/500"
            }
          ]
        }
      ],
      activeTabIndex: 0
    };
  },
  methods: {
    changeActiveTab(activeIndex) {
      this.activeTabIndex = activeIndex;
    },
    glideArrowClick() {}
  }
};
