
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { highlight } from "instantsearch.js/es/helpers";
import { connectSearchBox, connectHits } from "instantsearch.js/es/connectors";
export default {
  components: {},
  data: () => {
    return {
      showLoader: false,
      results: []
    };
  },
  head() {
    return {
      htmlAttrs: {
        lang: "en"
      },
      title: this.$store.getters["meta/metaTitle"],
      meta: this.$store.getters["meta/meta"],
      link: [
        {
          rel: "canonical",
          href: this.$store.getters["meta/canonicalUrl"]
            ? this.$store.getters["meta/canonicalUrl"]
            : "https://www.tatacommunications.com" + this.$route.fullPath
        }
      ]
    };
  },
  beforeCreate() {
    this.showLoader = true;
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.showLoader = false;
    this.getSearch();

    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {
    getSearch() {
      let results = true;

      let __vm = this;
      //Init search instance
      const search = instantsearch({
        indexName: "tata_faqs",
        searchClient: algoliasearch(
          "R9I7UBLORR",
          "cb54f2e697020224a974b248b9d6cf84"
        ),
        searchFunction(helper) {
          if (helper.state.query && helper.state.query.length >= 3) {
            helper.search();
          }
        }
      });

      const renderSearchBox = (renderOptions, isFirstRender) => {
        const { query, refine, clear, isSearchStalled, widgetParams } =
          renderOptions;
        if (isFirstRender) {
          //Set Query Params to input value

          const input = document.createElement("input");

          input.classList.add("search__input");
          input.placeholder = "Search For FAQ";

          input.addEventListener("input", (event) => {
            refine(event.target.value);
            if (!event.target.value) {
              results = false;
            } else {
              results = true;
            }
          });

          widgetParams.container.appendChild(input);
        }

        widgetParams.container.querySelector("input").value = query;
      };

      const customSearchBox = connectSearchBox(renderSearchBox);

      search.addWidgets([
        customSearchBox({
          container: document.querySelector("#searchbox")
        })
      ]);

      //Render results
      // Create the render function
      const renderHits = (renderOptions, isFirstRender) => {
        const { hits, widgetParams } = renderOptions;

        if (!isFirstRender) {
          if (hits.length && results) {
            __vm.results = hits;
          } else {
            results = false;
            __vm.results = [];
          }
        }
      };

      // Create the custom widget
      const customHits = connectHits(renderHits);

      // Instantiate the custom widget
      search.addWidgets([
        customHits({
          container: document.querySelector("#searchResults")
        })
      ]);

      search.start();
    },
    goBack() {
      if (history.length > 2) {
        this.$router.back();
      } else {
        window.location.href = "/";
      }
    }
  }
};
