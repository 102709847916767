
  import { Glide, GlideSlide } from "vue-glide-js";
  import AwardDetails from "~/components/global-components/award-details";
  export default {
    components: {
      AwardDetails,
      [Glide.name]: Glide,
      [GlideSlide.name]: GlideSlide,
    },
    data: () => {
      return {
        filter: {
          analyst_firm: [],
          position: [],
          portfolio: [],
          business_impact: []
        },
        slider_model: [],
        years: [],
        selectedYearArr: [],
        activeTab: 0,
        allAwards: [],
        filteredAwards: [],
        filteredData: [],
        filteredYear: [],
        categories: [],
        pagination: 4,
        yearSwiper:null,
      };
    },
    async fetch() {
      let { store } = this.$nuxt.context;
      let awards = await store.dispatch("analyst-recognition/getAllAwards");
      if(!awards)return;
      this.allAwards = this.filteredAwards = awards?.all_analyst_recognistion;
      this.categories = store.getters["analyst-recognition/allCategories"]; 
      this.years = this.filteredYear = Object.keys(this.allAwards).sort(function (
        a,
        b
      ) {
        return b - a;
      });
    },
    watch: {
      filter: {
        deep: true,
        handler({ analyst_firm, position, portfolio, business_impact }) {
          let filterd_array = [];
          if (analyst_firm.length || position.length || portfolio.length || business_impact.length) {
            filterd_array = [];
            this.pagination = 4;
            if(!this.allAwards)return;
            Object.keys(this.allAwards).filter((year) => {
              this.allAwards[year].filter((ele) => {
                if (ele.analyst_firm) {
                  ele.analyst_firm.forEach((item) => {
                    if (analyst_firm.includes(item.term_id)) {
                      if (!filterd_array[year]) {
                        filterd_array[year] = [];
                      }
                      filterd_array[year].push(ele);
                    }
                  });
                }
                if (ele.position) {
                  ele.position.forEach((item) => {
                    if (position.includes(item.term_id)) {
                      if (!filterd_array[year]) {
                        filterd_array[year] = [];
                      }
                      filterd_array[year].push(ele);
                    }
                  });
                }
                if (ele.portfolio) {
                  ele.portfolio.forEach((item) => {
                    if (portfolio.includes(item.term_id)) {
                      if (!filterd_array[year]) {
                        filterd_array[year] = [];
                      }
                      filterd_array[year].push(ele);
                    }
                  });
                }
                if (ele.business_impact) {
                  ele.business_impact.forEach((item) => {
                    if (business_impact.includes(item.term_id)) {
                      if (!filterd_array[year]) {
                        filterd_array[year] = [];
                      }
                      filterd_array[year].push(ele);
                    }
                  });
                }
              });
            });
            this.filteredYear = [];
            if(!filterd_array)return;
            this.filteredYear = Object.keys(filterd_array).sort((a, b) => {
              return b - a;
            });
            this.filteredAwards = filterd_array;
          } else {
            this.filteredAwards = this.allAwards;
            this.filteredYear = this.years;
          }
        }
      }
    },
    methods: {
      openNCloseFilter() {
        let filter = this.$refs.filterList;
        if (filter.classList.contains("d-block")) {
          filter.classList.remove("d-block");
        } else {
          filter.classList.add("d-block");
        }
      },
      
      *chunks(arr, n) {
        for (let i = 0; i < arr.length; i += n) {
          yield arr.slice(i, i + n);
        }
      },
  
      handleYearclick(selectedYear) {
        let secondaryToLatestYear = this.years[1];
        let latestyear = this.years[0];
  
        let currentSelectedYear = this.selectedYearArr;  //[2021]
  
        //** checks if the selected year is already available in seletedYearArr
        // do nothing if true
        console.log(currentSelectedYear, selectedYear);
        if (currentSelectedYear.includes(Number(selectedYear))) {
          return null;
        }
        //**
  
        ///** save selected year in an array. keeping everything unique and sorted
        currentSelectedYear.push(Number(selectedYear));
        let uniqueCurrentSelectedYear = [...new Set(currentSelectedYear)];
        uniqueCurrentSelectedYear.sort((a, b) => b - a);
        ///**
  
        // if the seletcted year is latest or secondary to latest year then do nothing e.g. for 2024 and 2023 do nothing
        if (secondaryToLatestYear <= selectedYear) {
          return null;
        }
  
        ///** extract minimum and maximum year from the selectedYearArr
        let selectedYearLength = uniqueCurrentSelectedYear.length;
        let maxSelectedYear = uniqueCurrentSelectedYear[0];
        let minselectedYear = uniqueCurrentSelectedYear[selectedYearLength - 1];
        let numOfTimeToCallLoadMore = (minselectedYear + 1) - selectedYear;
        ///**
  
        // if selectedYearArr length is 1.
        if (maxSelectedYear == minselectedYear) {
          numOfTimeToCallLoadMore = secondaryToLatestYear - selectedYear
        }
  
        let newyeararr = [];
        for (let i = Number(selectedYear); i < latestyear; i++) {
          newyeararr.push(i);  // [2023, 2023, 2022, 2021]
        }
        this.selectedYearArr = [...new Set(newyeararr)];
  
        for (let i = 0; i < numOfTimeToCallLoadMore; i++) {
          this.loadMore();
        }
      },
  
      loadMore() {
        this.pagination += 1;
      },
  
      addMoreYear() {
        let allSelectedyear = [];
        let latestyear = this.years[0];
        let minimumYear = latestyear - (this.pagination - 1);
  
        for (let i = minimumYear; i <= latestyear; i++) {
          allSelectedyear.push(i);
        }
  
        this.selectedYearArr = allSelectedyear;
      },

      initializeSwiper() {
        if (typeof Swiper !== 'undefined') {
          this.yearSwiper = new Swiper(".mySwiper", {
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        } else {
          console.error('Swiper is not defined');
        }
      },
    },
    mounted(){
      this.initializeSwiper();
    }
  };
