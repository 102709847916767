export const state = () => ({
  customer: null
});
export const mutations = {
  getCustomerData(state, data) {
    state.customer = data;
  }
};
export const actions = {
  async updateCustomerData({ commit }) {
    try {
      const res = await this.$axios.get(
        `/page1/?post_type=page&slug=our-customers`,
        {
          useCache: true
        }
      );
      commit("getCustomerData", res);
      return res;
    } catch (err) {
      console.error(err);
    }
  }
};
