
export default {
  beforeRouteLeave() {
    this.$refs["popupForm"].hide();
    let body = document.getElementsByTagName("body");
    body[0].classList.remove("modal-open");
  },
  props: {
    formData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    }
  },
  created() {
    if (process.client) {
      let body = document.querySelector("body");
      // eslint-disable-next-line no-unused-vars
      this.$nuxt.$on("open-popup-form", (ele) => {
        if (this.$refs["popupForm"]) {
          this.$refs["popupForm"].show();
        }
      });
    }
  }
};
