
import { mapGetters, mapState } from "vuex";
import { Glide, GlideSlide } from "vue-glide-js";
import { testimonialCarousel } from "~/assets/sliderConfig/testimonialCarousel";
import { globalPartnersCarousel } from "~/assets/sliderConfig/globalPartnersCarousel";
import { newsBlogCarousel } from "~/assets/sliderConfig/newsBlogCarousel";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      testimonialCarousel: testimonialCarousel(),
      globalPartnersCarousel: globalPartnersCarousel(),
      newsBlogCarousel: newsBlogCarousel(),
      resourceData: null,
      resourceRightData: null,
      filter: {
        search: "",
        year: [],
        type: []
      }
    };
  },
  async fetch() {
    // const { store, params } = this.$nuxt.context;
    try {
      let res = await Promise.all([
        this.$store.dispatch("resource/getResourcePage")
      ]);
      this.resourceData = res[0].data;
    } catch (err) {
      console.error(err);
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: "en"
      },
      title: this.$store.getters["meta/metaTitle"],
      meta: this.$store.getters["meta/meta"],
      link: [
        {
          rel: "canonical",
          href: this.$store.getters["meta/canonicalUrl"]
            ? this.$store.getters["meta/canonicalUrl"]
            : "https://www.tatacommunications.com" + this.$route.fullPath
        }
      ]
    };
  },
  computed: {
    ...mapGetters("resource", ["allData"]),
    ...mapState("resource", ["showSearch"]),
    searchTerm: function () {
      let returnVal = "Search results";
      if (this.filter.search != "") {
        returnVal = `Search results for "${this.filter.search}"`;
      }
      return returnVal;
    }
  },
  watch: {
    "$route.path"() {
      this.clearAllSearch();
      this.$refs.filterList.classList.remove("d-block");
    },
    filter: {
      deep: true,
      handler({ search, type, year }) {
        try {
          const sanitizedSearch = search.replace(/[^a-zA-Z0-9/ ]/g, "");
          this.filter.search = sanitizedSearch;
          if (sanitizedSearch === "" && type.length === 0 && year.length === 0) {
            this.$store.commit("resource/updateShowSearch", false);
          } else {
            if (!this.showSearch) {
              this.$store.commit("resource/updateShowSearch", true);
            }
            const filteredResults = this.allData.filter((e) => {
              let searchReturn = true;
              let typeReturn = true;
              let yearReturn = true;
              if (search !== "") {
                searchReturn =
                  e.title.toLowerCase().indexOf(search.toLowerCase()) > -1;
              }
              if (type.length > 0) {
                if (e.taxo.type.length > 0) {
                  typeReturn = type.indexOf(e.taxo.type[0].term_id) > -1;
                } else typeReturn = false;
              }
              if (year.length > 0) {
                if (e.taxo.year.length > 0) {
                  yearReturn = year.indexOf(e.taxo.year[0].term_id) > -1;
                } else yearReturn = false;
              }

              return searchReturn && typeReturn && yearReturn;
            });
            const filterCommitData = [
              {
                items: filteredResults,
                name:
                  filteredResults.length > 0
                    ? this.searchTerm
                    : `Sorry! We couldn’t find any matches for "${this.filter.search}"!`,
                hideButton: true
              }
            ];
            this.$store.commit("resource/updateFilter", filterCommitData);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  },
  mounted() {},
  methods: {
    clearAllSearch() {
      this.filter.search = "";
      this.filter.type = [];
      this.filter.year = [];
    },
    openNCloseFilter() {
      let filter = this.$refs.filterList;
      if (filter.classList.contains("d-block")) {
        filter.classList.remove("d-block");
      } else {
        filter.classList.add("d-block");
      }
    }
  }
};
