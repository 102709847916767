
import { Glide, GlideSlide } from "vue-glide-js";
import { section19 } from "~/assets/sliderConfig/section19";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      section19: section19()
    };
  }
};
