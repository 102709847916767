
import { twitterCardCarousel } from "~/assets/sliderConfig/twitterCardCarousel";
import { Glide, GlideSlide } from "vue-glide-js";
export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      resourceCarousel: twitterCardCarousel()
    };
  },
  mounted() {
    let twitterFeed = document.createElement("script");
    twitterFeed.setAttribute("src", "https://platform.twitter.com/widgets.js");
    document.head.appendChild(twitterFeed);
  },
  created() {}
};
