
import { Glide, GlideSlide } from "vue-glide-js";
import { globalPartnersCarousel } from "~/assets/sliderConfig/globalPartnersCarousel";
export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      globalPartnersCarousel: globalPartnersCarousel()
    };
  },
  mounted() {},
  methods: {
    urlLink(args) {
      if (args.indexOf("http://") == 0 || args.indexOf("https://") == 0) {
        return true;
      } else if (args || args === undefined || args === null || args === "") {
        return false;
      } else {
        return true;
      }
    }
  }
};
