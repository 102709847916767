
export default {
  props: {
    modifiers: {
      type: String,
      default: ""
    },
    ctaContent: {
      type: String,
      default: ""
    },
    solutionTypeBanner : {
      type: Boolean,
      default: false
    }
  },
  mounted() {}
};
