
export default {
  data (){
    return{
      homeLink:null,
    }
  },
  mounted(){
    this.homeLink = window.location.origin
  }
};
