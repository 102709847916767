
import { Glide, GlideSlide } from "vue-glide-js";
import { resourceCarousel } from "assets/sliderConfig/resourceCarousel";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data() {
    return {
      activeTabIndex: 0,
      resourceCarousel: resourceCarousel()
    };
  }
};
