
import gsap from "gsap";

export default {
    setup () {
        const beforeEnterLottie = (el) => {
            el.style.opacity = 0;
            el.style.transform = `translateX(-500px)`;
        };

        const enterLottie = (el, done) => {
            gsap.to(el, {
                x: 0,
                opacity: 1,
                duration: 0.8,
                delay: 0.2,
                onComplete: done
            });
        };

        return {
            beforeEnterLottie,
            enterLottie,
        };
    },
    head() {
        return {
            script: [
                {
                hid: "lottie-player",
                async: true,
                defer: true,
                src: "/lottie/lottie-player.js"
                }
            ]
        };
    },
}
