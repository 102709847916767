
import lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "assets/lottie/CutAnimation.json";
export default {
  components: {
    lottie
  },
  props: {
    modifiers: {
      type: String,
      default: ""
    },
    bannerData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    },
    solutionType : {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      bannerUrl: "",
      playerOptions: {
        preload: true,
        autoplay: true,
        controls: false,
        muted: true,
        loop: true,
        fill: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false
        }
      },
      anim: null, // for saving the reference to the animation
      lottieOptions: { animationData: animationData.default }
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    }
  },

  methods: {
    showBannerModal(val) {
      this.$refs["bannerModal"].show();
      // this.bannerUrl = val;
      const videoSrc = val;
      var videoSrcNew = videoSrc + "/?autoplay=1&rel=0&mute=1";
      this.bannerUrl = videoSrcNew;
    },
    onPlayerPlay(player) {},

    playVideo: function (source) {
      const player = this.$refs.videoPlayer.player;
      const video = {
        withCredentials: false,
        type: "application/x-mpegurl",
        src: source
      };
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);
      // this.player.load()
      this.player.play();
    },
    onPlayerReady(player) {
      const src = this.bannerData.streaming_link;
      this.playVideo(src);
      // this.player.play();
    },
    handleAnimation: function (anim) {
      this.anim = anim;
    }
  },
  mounted() {
    var links = document.getElementsByClassName("sustainBanner");
    for (var i = 0; i < links.length; i++) {
      // links[i].removeAttribute("title");
      var str = links[i].getAttribute("title");

      str = str.replace(/\//g, "");
      str = str.replace(/<abbr class="green">|<span>|<abbr>/g, "");
      links[i].setAttribute("title", str);
    }
    var links = document.querySelectorAll(".sustainBanner .banner");
    for (var i = 0; i < links.length; i++) {
      links[i].removeAttribute("title");
    }
  }
};
