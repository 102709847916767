
import { section28 } from "~/assets/sliderConfig/section28";
import { Glide, GlideSlide } from "vue-glide-js";
export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      section28: section28()
    };
  },
  mounted() {}
};
