
  import { Glide, GlideSlide } from "vue-glide-js";
  import { sectionThreeCarousel } from "~/assets/sliderConfig/sectionThreeCarousel";
  export default {
    components: {
      [Glide.name]: Glide,
      [GlideSlide.name]: GlideSlide
    },
    data: () => {
      return {
        sectionThreeCarousel: sectionThreeCarousel()
      };
    },
    mounted() {}
  };
  