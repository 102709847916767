
  export default {
    props: {
      nextArrow: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {};
    },
    computed: {
      arrowType: function () {
        if (this.nextArrow == true) {
          return "tata__indicators--arrowNext";
        } else {
          return "";
        }
      }
    },
    mounted: function () {}
  };
  