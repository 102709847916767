
export default {
  props: {
    imgSrc: {
      type: String,
      default: ""
    },
    imgClass: {
      type: String,
      default: ""
    }
  },
  computed: {
    imageType() {
      let imgType = this.imgSrc.match(
          /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim
        )[0],
        returnType = "";
      switch (imgType) {
        case ".jpg":
        case ".jpeg":
          returnType = "jpg";
          break;
        case ".png":
          returnType = "png";
          break;
        default:
          returnType = "";
      }
      return returnType;
    }
  },
  methods: {}
};
