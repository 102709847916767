
import { Glide, GlideSlide } from "vue-glide-js";
import { section73 } from "assets/sliderConfig/section73";
export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      section73: section73(),
      currentSliderIndex: 0
    };
  },
  methods: {
    moveSlide(index) {
      this.$refs.analystQuote.glide.go(`=${index}`);
    }
  }
};
