import { render, staticRenderFns } from "./tata-img.vue?vue&type=template&id=2f22f517&scoped=true"
import script from "./tata-img.vue?vue&type=script&lang=js"
export * from "./tata-img.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f22f517",
  null
  
)

export default component.exports