
import { Glide, GlideSlide } from "vue-glide-js";
import { newhomebanner } from "~/assets/sliderConfig/newhomebanner";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  data: () => {
    return {
      newhomebanner: newhomebanner(),
      bannerUrl: "",
    };
  },
  methods: {
    showBannerModal(val) {
      this.$refs["bannerModal"].show();
      this.bannerUrl = val;
    },
  },
};
