
export default {
  data: () => {
    return {};
  },
  mounted() {
},
methods: {
  
},

};
