export const testimonialCarousel = () => {
  return {
    focusAt: "center",
    startAt: 3,
    autoplay: false,
    perView: 3.2,
    type: "carousel",
    dragThreshold: false,
    bullet: false,
    gap: 30,
    animationTimingFunc: "ease-in-out",
    breakpoints: {
      992: {
        perView: 1.3,
        gap: 20
      }
    }
  };
};
