
export default {
  data: () => {
    return {
      remaining_rating: 0,
      isGartnerScriptLoad: false
    };
  },
  mounted() {
    this.remaining_rating = 5 - this.$attrs.rating;
    let __this = this;
    function loadGartnerWidget() {
      __this.isGartnerScriptLoad = true;
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        GartnerPI_Widget({
          size: "large",
          theme: "light",
          sourcingLink: "",
          widget_id: __this.$attrs.widget_id,
          version: "2",
          container: document.querySelector(`#widgetContainer`)
        });
      }, 500);
    }

    if (this.$attrs.has_gartner_widget && this.$attrs.widget_id) {
      let rating_widget = document.querySelector(".rating__widget");

      let ScriptTag = document.createElement("script");
      ScriptTag.src =
        "https://www.gartner.com/reviews/public/Widget/js/widget.js";
      ScriptTag.onload = loadGartnerWidget();
      rating_widget.appendChild(ScriptTag);
    }
  }
};
