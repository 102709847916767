
export default {
  data: () => {
    return {};
  },
  mounted() {
    if (document.querySelector(".mainpage")) {
      if (window.innerWidth > 993) {
        const $cards = document.querySelectorAll(
          ".mainpage .stack-cards .stackcard"
        );
        const $title = document.querySelector(
          ".mainpage .stack-cards .head-card"
        );

        // Constants
        const OFFSET = 70;

        const { marginBottom } = getComputedStyle($cards[0]);
        const { height } = $title.getBoundingClientRect();
        let margin = parseInt(marginBottom, 10);

        $cards.forEach((card, index) => {
          card.style.top = `${index * OFFSET + height}px`;
          card.style.marginBottom = `${margin}px`;
          margin -= OFFSET;
        });
      }
    }

    $(document).ready(function () {
      $(".SolutionsList, .benefitsList").hide();
      $(".HeadAccord").unbind("click");
      $(".HeadAccord").click(function () {
        $(this).toggleClass("active");
        $(this).next().slideToggle();
      });
    });
  },

  methods: {}
};
