
  export default {
    props: {
      ctaContent: {
        type: String,
        default: "connect"
      },
      redirectLink: {
        type: String,
        default: "/"
      },
      target: {
        type: String,
        default: ""
      },
      uniqueId: {
        type: String,
        default: ""
      }
    },
    methods: {
      urlLink(args) {
        if (args.indexOf("http://") == 0 || args.indexOf("https://") == 0) {
          return true;
        } else if (args || args === undefined || args === null || args === "") {
          return false;
        } else {
          return true;
        }
      },
      newWindow(args) {
        if (args === "New Window") {
          window.open(
            document.URL,
            "_blank",
            "location=yes,height=570,width=520,scrollbars=yes,status=yes"
          );
        }
      },
      openPopup() {
        this.$nuxt.$emit("open-popup-form", "open");
      },
  
      gotoSection(section) {
        if (window.innerWidth < 500) {
          var options = {
            easing: "linear",
            lazy: false,
            offset: -100
          };
          this.$scrollTo(section, 500, options);
        } else {
          var options = {
            easing: "linear",
            lazy: false,
            offset: -140
          };
          this.$scrollTo(section, 500, options);
        }
      }
    }
  };
  