import Vue from "vue";

export default ({ app: {} }) => {
  // https://vuejs.org/v2/guide/custom-directive.html
  Vue.directive("interpolation", {
    // Called only once, when the directive is first bound to the element. This is where you can do one-time setup work.
    inserted(el) {
      let interval = setInterval(() => {
        updateLinks(el);
      }, 100);
      setTimeout(() => {
        clearInterval(interval);
      }, 1000);
    },
    update(el) {
      let interval = setInterval(() => {
        updateLinks(el);
      }, 100);
      setTimeout(() => {
        clearInterval(interval);
      }, 1000);
    },
    // Called after the containing component’s VNode and the VNodes of its children have updated.
    componentUpdated: (el) => {
      let interval = setInterval(() => {
        updateLinks(el);
      }, 100);
      setTimeout(() => {
        clearInterval(interval);
      }, 1000);
    },

    // Called only once, when the directive is unbound from the element.
    unbind: () => {}
  });
};
function updateLinks(el) {
  let params = new URLSearchParams(window.location.search);
  let utm_params = [];
  params.forEach(function (value, key) {
    if (key.startsWith("utm_")) {
      utm_params.push(key + "=" + value);
    }
  });
  let utm_search = utm_params.join("&");

  const navigate = (event) => {
    let href = event.currentTarget.getAttribute("href");
    let target = event.currentTarget.getAttribute("target");
    // let targetClass = event.currentTarget.getAttribute("class");
    if (href && href[0] === "/" && target !== "_blank" && !event.ctrlKey) {
      event.preventDefault();
      if (!!utm_search && href.indexOf(utm_search) === -1) {
        href = href + (href.indexOf("?") === -1 ? "?" : "&") + utm_search;
      }
      window.$nuxt._router.push(href);
    }
    if (href && href[0] === "#" && href !== "#") {
      event.preventDefault();
      window.scrollTo({
        top: document.querySelector(href).offsetTop - 150,
        behavior: "smooth"
      });
    }
  };

  let links = el.getElementsByTagName("a");

  const addListeners = (links) => {
    for (let i = 0; i < links.length; i++) {
      let src = links[i].getAttribute("href");
      const target = links[i].getAttribute("target");
      if (
        utm_search &&
        src &&
        src.indexOf(utm_search) === -1 &&
        src.indexOf("#") === -1
      ) {
        let url_link = src + (src.indexOf("?") === -1 ? "?" : "&") + utm_search;
        links[i].setAttribute("href", url_link);
      }

      // For improved security `rel="noopener"` will be added automatically if target is `_blank`
      // https://github.com/mathiasbynens/rel-noopener/
      if (target && target === "_blank") {
        links[i].setAttribute("rel", "noopener");
      }
      links[i].addEventListener("click", navigate, false);
    }
  };

  const removeListeners = (links) => {
    for (let i = 0; i < links.length; i++) {
      links[i].removeEventListener("click", navigate, false);
    }
    links = [];
  };

  addListeners(links);

  el.$componentUpdated = () => {
    removeListeners(links);
    Vue.nextTick(() => addListeners(links));
  };

  el.$destroy = () => el.removeEventListener("click", removeListeners(links));
}
