
  import gsap from "gsap";
  import { ref } from "vue";
  
  export default {
    setup() {
      const showDropDown = ref(false);
  
      const beforeEnterCard = (el) => {
        el.style.opacity = 0;
        el.style.transform = `translateX(500px)`;
      };
  
      const enterCard = (el, done) => {
        gsap.to(el, {
          x: 0,
          opacity: 1,
          duration: 0.8,
          delay: el.dataset.index * 0.2,
          onComplete: done
        });
      };
  
      const beforeEnterLottie = (el) => {
        el.style.opacity = 0;
        el.style.transform = `translateX(-500px)`;
      };
  
      const enterLottie = (el, done) => {
        gsap.to(el, {
          x: 0,
          opacity: 1,
          duration: 0.8,
          delay: 0.2,
          onComplete: done
        });
      };
  
      return {
        beforeEnterCard,
        enterCard,
        beforeEnterLottie,
        enterLottie,
        showDropDown
      };
    },
    data: () => {
      return {
        activeTabIndex: 0,
      };
    },
    head() {
      return {
        script: [
          {
            hid: "lottie-player",
            async: true,
            defer: true,
            src: "/lottie/lottie-player.js"
          }
        ]
      };
    },
    methods: {
      changeActiveTab(activeIndex) {
        this.activeTabIndex = activeIndex;
      }
    },
    mounted(){
    }
  };
  