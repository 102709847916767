
export default {
  props: {
    presentationData: {
      type: Object,
      require: true,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    },
    hasBoarded: {
      type: String,
      require: false,
      default: "false"
    }
  }
};
