
import { Glide, GlideSlide } from "vue-glide-js";
import { section58 } from "assets/sliderConfig/section58";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      section58: section58(),
      currentCategory: false,
      active: 2,
      star5: false,
      star4: false,
      star3: false,
      star2: false,
      star1: false,
      TotalNoVerifi: false,
      show_rating: false,
      ratings_out_of_5: false,
      counter: 0,
      showLoadmorButton: true,
      categoryStar: {
        full: 0,
        half: 0,
        empty: 0
      },
      reviews: false,
      popupItem: false
    };
  },
  computed: {},
  mounted() {
    this.getCurrentCategoryData(this.$attrs.categories[0]);
  },
  methods: {
    getCurrentCategoryData(item) {
      this.currentCategory = item;
      let reviews = item.reviews;
      this.counter = 6;
      this.reviews = reviews;
      let count5 = 0,
        count4 = 0,
        count3 = 0,
        count2 = 0,
        count1 = 0,
        totalRatingValue = 0;
      reviews.forEach((ele) => {
        if (ele.ratings && ele.ratings !== "") {
          ele.ratings = parseInt(ele.ratings);

          switch (ele.ratings) {
            case 5:
              count5 = count5 + 1;
              break;

            case 4:
              count4 = count4 + 1;
              break;

            case 3:
              count3 = count3 + 1;
              break;

            case 2:
              count2 = count2 + 1;
              break;

            case 1:
              count1 = count1 + 1;
              break;

            default:
          }

          totalRatingValue = totalRatingValue + ele.ratings; //  totalCount++;
        }
      });
      this.star5 = this.checkFloat((count5 / reviews.length) * 100) || 0;
      this.star4 = this.checkFloat((count4 / reviews.length) * 100) || 0;
      this.star3 = this.checkFloat((count3 / reviews.length) * 100) || 0;
      this.star2 = this.checkFloat((count2 / reviews.length) * 100) || 0;
      this.star1 = this.checkFloat((count1 / reviews.length) * 100) || 0;

      this.TotalNoVerifi = reviews.length;
      this.show_rating = this.checkFloat(totalRatingValue / reviews.length);
      this.ratings_out_of_5 = parseInt(this.show_rating * 2);
      this.categoryRating(this.ratings_out_of_5);
      if (this.TotalNoVerifi > 6) {
        this.showLoadmorButton = true;
      } else {
        this.showLoadmorButton = false;
      }
    },
    checkFloat: function checkFloat(n) {
      if (Number(n) === n && n % 1 !== 0) {
        return n.toFixed(1);
      } else {
        return n;
      }
    },
    categoryRating(rate) {
      let ratings_out_of_5 = rate; //create Star

      var starTotal = 10;
      var half_html = 0;
      var full_html = 0;
      var empty_html = 0;
      var remainingStar = 0;
      var starPercentage = (ratings_out_of_5 / starTotal) * 100; // var full_star =  (starPercentage / 10) / 2;

      var half_star = (starPercentage / 10) % 2;
      starPercentage /= 10;
      remainingStar = 10 - Math.round(ratings_out_of_5);

      if (remainingStar > 0) {
        for (var k = 1; k < remainingStar; k = k + 2) {
          empty_html++;
        }
      }

      if (half_star > 0) {
        starPercentage = starPercentage - half_star;
        half_html++;
      }

      for (var i = 0; i < starPercentage; i = i + 2) {
        full_html++;
      }

      this.categoryStar.full = full_html;
      this.categoryStar.half = half_html;
      this.categoryStar.empty = empty_html;
    },
    loadMore() {
      this.counter = this.counter + 6;
      if (this.TotalNoVerifi <= this.counter) {
        this.showLoadmorButton = false;
        return;
      }
    },
    openPopup(item) {
      this.$refs["gpi_popup"].show();
      this.popupItem = item;
    }
  }
};
