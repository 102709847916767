
import { resourceCarousel } from "~/assets/sliderConfig/resourceCarousel";
import { Glide, GlideSlide } from "vue-glide-js";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      resourceCarousel: resourceCarousel()
    };
  }
};
