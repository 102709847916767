
export default {
  props: {
    bottomType: {
      type: String,
      default: ""
    }
  },
  mounted() {}
};
