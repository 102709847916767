
import TataLink from "./tata-link.vue";
export default {
  components: { TataLink },
  layout: "faqs-inner",

  data: () => {
    return {
      showLoader: false
    };
  },
  head() {
    return {
      htmlAttrs: {
        lang: "en"
      },
      title: this.$store.getters["meta/metaTitle"],
      meta: this.$store.getters["meta/meta"],
      link: [
        {
          rel: "canonical",
          href: this.$store.getters["meta/canonicalUrl"]
            ? this.$store.getters["meta/canonicalUrl"]
            : "https://www.tatacommunications.com" + this.$route.fullPath
        }
      ]
    };
  },
  computed: {
    getFaqList() {
      return this.$store.getters["faqs/faqList"];
    },
    getBreadcrumbs() {
      let breadcrumb = this.$store.getters["faqs/breadcrumbs"];
      if (breadcrumb.length) {
        this.$store.commit(
          "l1/updatePageTitle",
          breadcrumb[breadcrumb.length - 1].name + " FAQs"
        );
      }

      return breadcrumb;
    }
  },
  beforeCreate() {
    this.showLoader = true;
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.showLoader = false;

    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {}
};
