
import { Glide, GlideSlide } from "vue-glide-js";
import { section77 } from "~/assets/sliderConfig/section77";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data() {
    return {
      section77: section77(),
      isGartnerScriptLoad: false
    };
  },
  head() {
    return {
      script: [
        {
          hid: "gartner",
          src: "https://www.gartner.com/reviews/public/Widget/js/widget.js",
          body: true,
          async: true,
          defer: true,
          // Changed after script load
          callback: () => {
            this.isGartnerScriptLoad = true;
            this.loadGartnerWidget();
          }
        }
      ]
    };
  },
  methods: {
    scripLoaded() {
      return this.isGartnerScriptLoad;
    },
    loadGartnerWidget() {
      setTimeout(() => {
        this.$attrs.widgets.forEach((ele, index) => {
          ele.container = "widgetContainer" + index;
          // eslint-disable-next-line no-undef
          GartnerPI_Widget({
            size: "large",
            theme: "light",
            sourcingLink: "",
            widget_id: ele.widget_id,
            version: "2",
            container: document.querySelector(`#${ele.container}`) //DOM node in which the widget is to be rendered [e.g. document.querySelector("#myNodeContainer")]
          });
        });
      }, 500);
    }
  }
};
