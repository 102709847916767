
  import { Glide, GlideSlide } from "vue-glide-js";
  import { resourceFeaturedSlider } from "~/assets/sliderConfig/resourceFeaturedSlider";
  
  export default {
    components: {
      [Glide.name]: Glide,
      [GlideSlide.name]: GlideSlide
    },
    data: () => {
      return {
        resourceFeaturedSlider: resourceFeaturedSlider(),
        slider_data: [],
        awardData:null,
      };
    },
    watch: {
      $route: function (to, from) {
        if (to.path !== from.path) {
          this.slider_data = [];
          this.updateSlider();
        }
      },
    },
    mounted() {
      this.slider_data = [];
      this.updateSlider();
      this.$forceUpdate();
    },
    methods: {
      updateSlider() {
        for (let item in this.$attrs.items) {
          this.awardData = this.$attrs.isAwards;
          let featured_image = this.$attrs.items[item].featured_image;
          if (!this.$attrs.items[item].has_featured_image) {
            featured_image = null;
          }
          const awardType = this.awardData ? "Featured Award" : "Featured Analyst Recognition";

          this.slider_data.push({
            title: this.$attrs.items[item].title,
            sub_title: this.$attrs.items[item].sub_title || awardType,
            description: this.$attrs.items[item].excerpt,
            featured_image: featured_image,
            slug: this.$attrs.items[item].slug,
            slug_title: this.$attrs.items[item].slug_title
          });
        }
      }
    }
  };
