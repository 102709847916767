
export default {
  props: {
    id: {
      type: Number,
      default: 1
    },
    className: {
      type: String,
      default: ""
    },
    ctaType: {
      type: String,
      default: "primary"
    }
  }
};
