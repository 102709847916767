
export default {
  data() {
    return {};
  },
  mounted() {
    document.addEventListener("scroll", this.scrollHander);
  },
  methods: {
    scrollHander() {
      let scroll = window.scrollY;
      let element = document.querySelector(".globle-partner-program-wrapp");

      if (element) {
        if (
          element.offsetTop - 180 <= scroll &&
          element.offsetTop + element.scrollHeight > scroll
        ) {
          document
            .querySelector(".globle-partner-program-list")
            .classList.add("addAnimation");
        } else {
          document
            .querySelector(".globle-partner-program-list")
            .classList.remove("addAnimation");
        }
      }
    }
  }
};
