
export default {
  props: {
    contactCardData: {
      type: Object,
      required: true,
      default: null
    }
  },
  methods: {}
};
