import { render, staticRenderFns } from "./tata-primary-text-card.vue?vue&type=template&id=4bf8679c&scoped=true"
import script from "./tata-primary-text-card.vue?vue&type=script&lang=js"
export * from "./tata-primary-text-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf8679c",
  null
  
)

export default component.exports