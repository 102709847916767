
//   import { Glide, GlideSlide } from "vue-glide-js";
//   import { section39 } from "~/assets/sliderConfig/section39";
  
  export default {
    components: {
    //   [Glide.name]: Glide,
    //   [GlideSlide.name]: GlideSlide
    },
    data: () => {
      return {
        // section39: section39()
      };
    },
    methods: {
    //   isSlider() {
    //     if (process.browser) {
    //       if (this.$attrs.cards.length > 4 || window.screen.width < 769) {
    //         return true;
    //       }
    //     }
    //     return false;
    //   }
    }
  };
  