
import { Glide, GlideSlide } from "vue-glide-js";
import { pressReleaseCarousel } from "~/assets/sliderConfig/pressReleaseCarousel";
export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      pressReleaseCarousel: pressReleaseCarousel()
    };
  }
};
