
import slideCard from "@/components/global-components/tata-slide-card.vue";
import selectedCard from "@/components/global-components/tata-youtubeCard.vue";
import { videoCarousel } from "~/assets/sliderConfig/videoCarousel";
import { Glide, GlideSlide } from "vue-glide-js";
export default {
  components: {
    slideCard,
    selectedCard,
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  props: {
    hasContainer: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  computed: {
    carouselOptions() {
      return {
        type: "swipe", // Use swipe type for scrolling
        perTouch: 1, // Move 1 slide per touch/swipe
        focusAt: 0, // Start focusing from the first slide
        rewind: false // Disable looping
      };
    }
  },
  data() {
    return {
      activeData: {},
      activeIndex: 0,
      swiperOptions: {
        slidesPerView: "auto",
        speed: 700,
        initialSlide: 2
      },
      resourceCarousel: videoCarousel(),
    };
  },
  
  mounted() {
    this.toggleYoutube(0);
  },
  methods: {
    toggleYoutube(index) {
      this.activeData = this.$attrs.video_data[index];
      this.activeIndex = index;
    },
    postLoad() {
      document
        .querySelector(
          ".section132 .swiper-wrapper .swiper-slide:nth-child(1) .cardSlide"
        )
        .click();
    },
    handleSmoothScroll(target) {
        let ele = document.querySelector(target);
        // ele.scrollIntoView();
        if (ele) {
          const scrollToOptions = {
            behavior: 'smooth', 
            block: 'start'   
          };
          ele.scrollIntoView(scrollToOptions);
        }
    }
  }
};
