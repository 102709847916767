
export default {
  data: () => {
    return {
      formData: {
        eventData: {
          event: "TataFormSubmit",
          category: "Form submit",
          action: "Page",
          label: process.server ? "" : window.location.href
        }
      }
    };
  },
  mounted(){}
};
