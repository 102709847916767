
export default {
  data() {
    return {
      activeIndex: 0
    };
  },
  methods: {
    changeTab(index) {
      this.activeIndex = index;
    },
  },
  mounted(){}
};
