
export default {
  data: () => {
    return {
      openVideo: false
    };
  },
  methods: {
    close() {
      this.openVideo = false;
      const el = document.getElementById("overviewVideo");
      const elSrc = el.src;
      el.src = elSrc;
    }
  }
};
