
export default {
  head() {
    return {
      __dangerouslyDisableSanitizers: ["script"],
      script: [
        {
          innerHTML: `_linkedin_partner_id = "4101050";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);`,
          type: `text/javascript`,
          charset: "utf-8"
        },
        {
          innerHTML: `(function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);`,
          type: `text/javascript`,
          charset: "utf-8"
        },
        {
          innerHTML: `window.lintrk('track', { conversion_id: 9943274 });`,
          type: `text/javascript`,
          charset: "utf-8"
        }
      ],
      noscript: [
        {
          innerHTML:
            '<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4101050&fmt=gif" />'
        }
      ],
      __dangerouslyDisableSanitizers: ["noscript"]
    };
  },
  computed: {}
};
