export const section58 = () => {
  return {
    perView: 5.3,
    focusAt: "center",
    type: "carousel",
    bullet: false,
    gap: 20,
    breakpoints: {
      992: {
        perView: 3.3,
        focusAt: "center"
      },
      620: {
        perView: 1.3,
        focusAt: "center",
        peek: 50
      }
    }
  };
};
