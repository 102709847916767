
import TataPrimaryCta from "../tata-primary-cta";
export default {
  components: { TataPrimaryCta },
  data() {
    return {
      popup_data: false
    };
  },
  methods: {
    openPopup(popupData) {
      this.popup_data = popupData;
      // this.$refs..
      this.$refs["zigzagPopup"].show();
    }
  }
};
