export const state = () => ({
  about: [],
  aboutData: {},
  pageTitle: "",
  headerCta: "",
  pageResponse: null,
  itemSpecificData: null,
  sntCookieUpdate: null,
  breadcrumbs: null
});
export const mutations = {
  getAboutPage(state, data) {
    state.about = data;
  },
  updateAboutData(state, data) {
    state.aboutData = data;
  },
  updatePageTitle(state, data) {
    state.pageTitle = data;
  },
  updateItemSpecificData(state, data) {
    state.itemSpecificData = data;
  },
  updatePageResponse(state, data) {
    state.pageResponse = data;
  },
  updateHeaderCta(state, data) {
    state.headerCta = data;
  },
  updateSntCookiesUpdate(state, data) {
    state.sntCookieUpdate = data;
  },
  updateBreadcrumbs(state, data) {
    state.breadcrumbs = data;
  }
};
export const getters = {
  pageTitle: (state) => {
    return state.pageTitle;
  },
  pageData: (state) => {
    return state.pageResponse;
  },
  sntCookieUpdate: (state) => {
    return state.sntCookieUpdate;
  },
  breadcrumbs: (state) => {
    return state.breadcrumbs;
  }
};
export const actions = {
  async updateAboutPage({ commit }, params) {
    try {
      const res = await this.$axios.get(`page?slug=${params.slug}`);
      commit("updateAboutData", res);
      return res;
    } catch (err) {
      console.error(err);
    }
  },
  async getL1TemplateData({ commit }, params) {
    try {
      let paramStr = "";
      let post_type_str = "";
      let post_type_list = [
        "solutions",
        "products",
        "digital-futures",
        "sports",
        "innovations"
      ];
      let post_type = params.l1;
      let params_length = Object.keys(params).length;

      if (params) {
        if (params.l1) {
          if (post_type_list.indexOf(post_type) === -1 || !params.slug) {
            post_type = "page";
          }

          if (post_type === "products") {
            post_type = "product";
          }

          post_type_str = "post_type=" + post_type;

          if (post_type === "page" && params.slug && params_length <= 2) {
            post_type_str = post_type_str + "&parent1=" + params.l1;
          }
          if (!params.slug) {
            post_type_str = post_type_str + "&slug=" + params.l1;
          }
        }

        if (params.parent1) {
          if (post_type === "page") {
            paramStr = "&parent2=" + params.l1 + "&parent1=" + params.parent1;
          } else {
            paramStr = "&parent1=" + params.parent1;
          }
        }
        if (params.parent2) {
          if (post_type === "page") {
            paramStr =
              "&parent3=" +
              params.l1 +
              "&parent2=" +
              params.parent1 +
              "&parent1=" +
              params.parent2;
          } else {
            paramStr =
              "&parent2=" + params.parent1 + "&parent1=" + params.parent2;
          }
        }
        if (params.parent3) {
          if (post_type === "page") {
            paramStr =
              "&parent4=" +
              params.l1 +
              "&parent3=" +
              params.parent1 +
              "&parent2=" +
              params.parent2 +
              "&parent1=" +
              params.parent3;
          } else {
            paramStr =
              "&parent3=" +
              params.parent1 +
              "&parent2=" +
              params.parent2 +
              "&parent1=" +
              params.parent3;
          }
        }
        if (params.parent4) {
          if (post_type === "page") {
            paramStr =
              "&parent5=" +
              params.l1 +
              "&parent4=" +
              params.parent1 +
              "&parent3=" +
              params.parent2 +
              "&parent2=" +
              params.parent3 +
              "&parent1=" +
              params.parent4;
          } else {
            paramStr =
              "&parent4=" +
              params.parent1 +
              "&parent3=" +
              params.parent2 +
              "&parent2=" +
              params.parent3 +
              "&parent1=" +
              params.parent4;
          }
        }
        if (params.parent5) {
          paramStr =
            "&parent5=" +
            params.parent1 +
            "&parent4=" +
            params.parent2 +
            "&parent3=" +
            params.parent3 +
            "&parent2=" +
            params.parent4 +
            "&parent1=" +
            params.parent5;
        }

        if (params.slug) {
          paramStr = paramStr + "&slug=" + params.slug;
        }
      }

      let res = await this.$axios.get(`page1/?${post_type_str}${paramStr}`, {
        useCache: true
      });
      if (res && res.data) {
        commit("updatePageTitle", res.data.page_title);
        commit("updateItemSpecificData", res.data.item_specific_data);
        commit("updatePageResponse", res.data);
      } else {
        commit("updatePageTitle", "");
        commit("updateItemSpecificData", null);
        commit("updatePageResponse", null);
      }
      return res;
    } catch (err) {
      console.error(err);
    }
  },
  updatePageTitle({ commit }, param) {
    commit("updatePageTitle", param);
  },
  updateItemSpecificData({ commit }, param) {
    commit("updateItemSpecificData", param);
  }
};
