export const state = () => ({
  globalLoader: false,
  glideArrowClicked: false,
  activeCardIndex: -1,
  expandPrimaryCard: false,
  viewTextVisibility: true,
  menuData: {},
  homeData: {},
  redirects: [],
  baseUrl: "",
  order: ["sort by oldest", "sort by newest"],
  activeOrder: null
  // pageLoadData: {}
});

export const getters = {
  globalLoader: (state) => {
    return state.globalLoader;
  }
};
export const mutations = {
  updateGlobalLoader(state, data) {
    state.globalLoader = data;
  },
  UpdateglideArrowClicked(state, data) {
    state.glideArrowClicked = data;
  },
  UpdateActiveCardIndex(state, data) {
    state.activeCardIndex = data;
  },
  UpdateExpandPrimaryCard(state, data) {
    state.expandPrimaryCard = data;
  },
  UpdateViewTextVisibility(state, data) {
    state.viewTextVisibility = data;
  },
  updateMenuData(state, data) {
    state.menuData = data;
  },
  updateHomeData(state, data) {
    state.homeData = data;
  },
  updateRedirects(state, data) {
    state.redirects = data;
  },
  baseUrl(state, data) {
    state.baseUrl = `https://${data}`;
  },
  setActiveOrder(state, data) {
    state.activeOrder = data;
  }
  // updatePageLoadData(state, data) {
  //     state.pageLoadData = data;
  //   },
};
// export const actions = { async updatePageLoadData({ commit },params) {
//     const res = await Promise.all([this.$axios.$get(`/page`, {params})]);
//     if (res) {
//       let commitData = res;
//       commit("updatePageLoadData", commitData);
//     }
//   },}

export const actions = {
  async nuxtServerInit({ commit }) {
    const res = await Promise.all([
      this.$axios.get(
        "https://gamma.tatacommunications.com/assets/wp-content/uploads/json/redirection-oct-29.json" // redirection JSON URL
      )
    ]);
    if (res && res[0]) {
      const redirectData = res[0];
      commit("updateRedirects", redirectData);
    }
  },
  async getHomeData({ commit }) {
    const res = await this.$axios.get(`page1/?post_type=page&slug=home-page`);
    commit("updateHomeData", res);
    return res.data;
  },
  async updateMenu({ commit }) {
    try {
      const res = await this.$axios.get(`menu`, {
        useCache: true
      });
      commit("updateMenuData", res.data);
      return res;
    } catch (err) {
      console.error(err);
    }
  }
};