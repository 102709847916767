export const sectionThreeCarousel = () => {
  return {
    perView: 3,
    type: "carousel",
    bullet: false,
    gap: 30,
    animationTimingFunc: "ease-in-out",
    breakpoints: {
      640: {
        perView: 2.5,
        focusAt: "center",
        gap: 15
      },
      450: {
        perView: 1.2,
        focusAt: "center",
        gap: 15
      }
    }
  };
};
