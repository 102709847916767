
export default {
  props: {
    cardData: {
      type: Object,
      default: null
    },
  },
  data: () => {
    return {
      active: false
    };
  },
  computed: {},
  mounted() {
    console.log("cta-id",this.cardData);
  }
};
