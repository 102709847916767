
export default {
  components: {
    section1: () =>
      import("~/components/global-components/section-components/section1.vue"),
    tataNewsCenterLargeCard: () =>
      import("~/components/global-components/tata-news-center-large-card.vue"),
    tataNewsCenterCard: () =>
      import("~/components/global-components/tata-news-center-card.vue")
  },
  props: {
    params: {
      type: String,
      require: true,
      default: ""
    }
  },
  data: () => {
    return {
      postType: null,
      pagination: {
        length: 10,
        start: 0
      },
      banner: false,
      breadCrumb: false,
      news_centers: [],
      all_article: [],
      showLoader: false
    };
  },
  async fetch() {
    try {
      const { store } = this.$nuxt.context;
      const params = { l1: this.params };
      const res = store.getters["news-center/newCenterList"];

      let item_specific_data = {
        should_the_api_for_this_page_or_item_have_header_and_footer_menu: false,
        custom_css: "",
        contact_us_cta: {
          text: "Contact Us",
          url: "/contact-us",
          open_in: "New Window"
        },
        secondary_menu_list: [],
        show_secondary_menu_on_this_page: "yes",
        show_custom_links: "no"
      };
      store.commit("l1/updatePageTitle", this.$attrs.page_title);
      store.commit("l1/updateItemSpecificData", item_specific_data);
      store.commit("l1/updateBreadcrumbs", this.$attrs.breadscrum);
      this.all_article = res.data;
      this.news_centers = res.data.slice(
        this.pagination.start,
        this.pagination.length
      );
      this.banner = {
        title: this.$attrs.banner.title,
        sub_title: this.$attrs.banner.subtitle,
        banner_height: "Short Length"
      };
      this.breadCrumb = this.$attrs.breadscrum;
    } catch (error) {
      console.error(error);
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: "en"
      },
      title: this.$store.getters["meta/metaTitle"],
      meta: this.$store.getters["meta/meta"],
      link: [
        {
          rel: "canonical",
          href: this.$store.getters["meta/canonicalUrl"]
            ? this.$store.getters["meta/canonicalUrl"]
            : "https://www.tatacommunications.com" + this.$route.fullPath
        }
      ]
    };
  },
  beforeCreate() {
    this.showLoader = true;
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.showLoader = false;

    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {
    loadMore() {
      this.pagination.start += this.pagination.length;
      this.news_centers.push(
        ...this.all_article.splice(
          this.pagination.start,
          this.pagination.length
        )
      );
    }
  }
};
