export const pressReleaseCarousel = () => {
  return {
    focusAt: "center",
    perView: 1,
    type: "carousel",
    bullet: true,
    // gap: 20,
    animationTimingFunc: "ease-in-out"
  };
};
