
export default {
  props: {
    cardData: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {};
  },
  computed: {},
  mounted() {}
};
