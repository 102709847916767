
export default {
  props: {
    arrowPosition: {
      type: String,
      require: true,
      default: "before" //before,after
    },
    className: {
      type: String,
      require: true,
      default: ""
    }
  }
};
