
export default {
  data() {
    return {
      leaderPopup: false
    };
  },
  methods: {
    showModal(val) {
      this.$refs["leaderPopup"].show();
      this.leaderPopup = val;
    }
  }
};
