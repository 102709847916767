
export default {
  props: {
    cardData: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {
      active: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    // eslint-disable-next-line no-unused-vars
    openPopup(item) {
      //this.$emit("openPopup", item);
    }
  }
};
