
export default {
  props: {
    popupHandle: {
      type: String,
      require: true,
      default: ""
    }
  }
};
