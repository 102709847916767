
import { Glide, GlideSlide } from "vue-glide-js";
import { section59 } from "assets/sliderConfig/section59";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      section59: section59()
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
