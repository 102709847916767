export const resourceCenterCarousel = () => {
  return {
    focusAt: 0,
    startAt: 0,
    autoplay: false,
    perView: 3,
    type: "slider",
    dragThreshold: false,
    bullet: false,
    gap: 30,
    animationTimingFunc: "ease-in-out",
    breakpoints: {
      992: {
        perView: 1.3,
        gap: 10,
        type: "carousel",
        focusAt: "center",
        dragThreshold: true
      }
    }
  };
};
