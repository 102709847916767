
import { Glide, GlideSlide } from "vue-glide-js";
import { section18 } from "~/assets/sliderConfig/section18";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      section18: section18(),
      activeTabIndex: 0,
      bannerUrl: null
    };
  },
  mounted() {},
  methods: {
    changeActiveTab(activeIndex) {
      this.activeTabIndex = activeIndex;
    },
    showBannerModal(val) {
      this.$refs["video"].show();
      this.bannerUrl = val;
    },
    glideArrowClick() {},
    urlLink(args) {
      if (args.indexOf("http://") == 0 || args.indexOf("https://") == 0) {
        return true;
      } else if (args || args === undefined || args === null || args === "") {
        return false;
      } else {
        return true;
      }
    }
  }
};
