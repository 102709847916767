
import Vue from "vue";
import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);
    export default {
        data() {
            return{
                openVideo: false,
                playerVars: {
                    autoplay:0,
                    controls: 0,
                    rel: 0,
                    showinfo: 0,
                    wmode: "transparent",
                },
                isFullScreen:false,
                timer: null,
                youtubePlayer: null,
                currentTime: 0,
                youtubeLink:null,
                videoLink:null,
                VideoSrc:null,
                videoFormData:{
                    eventData:{
                        event: "TataFormSubmit",
                        category: "Form submit",
                        action: "Popup",
                        label:process.client ? window.location.href : ''
                    },
                    form1_section:{
                        thank_you_message: ""
                    },
                    form2_section:{
                        overide_redirect:false,
                        redirection_url: ""
                    },
                    form_id:this.$attrs.video_form_id,
                    form_type: "form1",
                    form_title: "",
                    noScroll:true,
                },
                formSubmitted:null,
            }
        },
        computed: {
            videoId() {
                return this.$youtube.getIdFromUrl(this.youtubeLink);
            },
        },
        mounted(){
            this.videoSrc = this.$attrs.video_link;
            if (this.videoSrc.startsWith("https://www.youtube.com/")) {
                this.youtubeLink = this.videoSrc;
            }else {
                this.videoLink = this.videoSrc;
            }
        },
        methods:{
            open() {
                this.openVideo = true;
                /* play video on click */
                const videoPlayer = document.querySelector('.videoPlayer');
                if (videoPlayer) {
                    videoPlayer.play();
                    // calling form on playing the video
                    videoPlayer.addEventListener('timeupdate', this.onVideoPlay);
                }

                let images = document.querySelectorAll('.iframeContainer');
                images.forEach(image => {
                    image.style.zIndex = "5";
                });
            },
            closeVideo() {
                this.openVideo = false; 
                
                /* pause video on click */
                const videoPlayer = document.querySelector('.videoPlayer');
                if (videoPlayer) {
                    videoPlayer.pause();
                    videoPlayer.currentTime=0;
                }

                let images = document.querySelectorAll('.iframeContainer');
                images.forEach(image => {
                    image.style.zIndex = "1";
                });
            },
            
            toggleFullScreen() {
                const iframe = document.querySelector('#overviewVideo');
                if (!this.isFullScreen) {
                    if (iframe.requestFullscreen) {
                        iframe.requestFullscreen();
                    } else if (iframe.webkitRequestFullscreen) {
                        /* Safari */
                        iframe.webkitRequestFullscreen();
                    } else if (iframe.msRequestFullscreen) {
                        /* IE11 */
                        iframe.msRequestFullscreen();
                    }
                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitExitFullscreen) {
                        /* Safari */
                        document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) {
                        /* IE11 */
                        document.msExitFullscreen();
                    }
                }
                this.isFullScreen = !this.isFullScreen;
            },
            getCurrentTime() {
                // Get the current time of the video
                const videoPlayer = document.querySelector('.videoPlayer');
                if (videoPlayer) {
                    this.currentTime = videoPlayer.currentTime;
                    // Remove the event listener after getting the current time
                    videoPlayer.removeEventListener('play', this.getCurrentTime);
                }
            },
            onVideoPlay() {
                const videoPlayer = document.querySelector('.videoPlayer');
                const backendVideoPauseTime = parseInt(this.$attrs.video_pause_time) / 1000; //parsing the milliseconds to seconds coming from backend

                if (videoPlayer && !this.formSubmitted) {
                    const currentTimeInSeconds = Math.floor(videoPlayer.currentTime); // Converting current time to seconds

                    // Checkinf if current time is greater than backend time
                    if (currentTimeInSeconds >= backendVideoPauseTime) {
                        // Executing the modal popup
                        this.$refs["overviewModal"].show();
                        videoPlayer.pause();
                    } else {
                        // Adjust for a small time difference
                        if (Math.abs(currentTimeInSeconds - backendVideoPauseTime) <= 1) {
                            // Executing the modal popup
                            this.$refs["overviewModal"].show();
                            videoPlayer.pause();
                        }
                    }
                }
            },
            resetVideo() {
                if (!this.formSubmitted){
                    this.closeVideo();
                }
            },
            handleFormSubmissionSuccess(param){
                this.formSubmitted = param;
                setTimeout(() => {
                    if (this.formSubmitted) {
                    this.$refs["overviewModal"].hide();
                    const videoPlayer = document.querySelector('.videoPlayer');
                    if (videoPlayer) {
                        videoPlayer.play();
                    }
                }
                }, 1000);
                
            },
        }
    }
