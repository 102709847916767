export const state = () => ({
  metaTitle: "Tata Communications",
  meta: [
    {
      hid: "utf-8",
      charset: "utf-8"
    },
    {
      hid: "viewport",
      name: "viewport",
      content: "width=device-width, initial-scale=1"
    },
    {
      hid: "description",
      name: "description",
      content: "Tata Communications"
    },
    // {
    //   hid: "keywords",
    //   name: "keywords",
    //   content: "Tata Communications"
    // }
  ],
  canonicalUrl: ""
});

export const getters = {
  meta: (state) => {
    return state.meta;
  },
  metaTitle: (state) => {
    return state.metaTitle;
  },
  canonicalUrl: (state) => {
    return state.canonicalUrl;
  }
};

export const mutations = {
  updateMeta(state, data) {
    state.meta = data;
  },
  updateMetaTitle(state, data) {
    state.metaTitle = data;
  },
  updateCanonicalUrl(state, data) {
    state.canonicalUrl = data;
  }
};

export const actions = {
  async updateMeta({ commit }, { metaData, swiftMeta, language_meta }) {
    let meta = [
      {
        hid: "utf-8",
        charset: "utf-8"
      },
      // {
      //   hid: "keywords",
      //   name: "keywords",
      //   content: "Tata Communications"
      // }
    ];
    if (metaData) {
      if (metaData.fb_title) {
        meta.push({
          hid: "og:title",
          property: "og:title",
          content: metaData.fb_title
        });
      }
      if (metaData.fb_description) {
        meta.push({
          hid: "og:description",
          property: "og:description",
          content: metaData.fb_description
        });
      } else {
        meta.push({
          hid: "og:description",
          property: "og:description",
          content: ""
        });
      }
      if (metaData.fb_image) {
        meta.push({
          hid: "og:image",
          property: "og:image",
          content: metaData.fb_image
        });
      }
      if (metaData.fb_image_width) {
        meta.push({
          hid: "og:image:width",
          property: "og:image:width",
          content: metaData.fb_image_width
        });
      }
      if (metaData.fb_image_height) {
        meta.push({
          hid: "og:image:height",
          property: "og:image:height",
          content: metaData.fb_image_height
        });
      }
      if (metaData.fb_locale) {
        meta.push({
          hid: "og:locale",
          property: "og:locale",
          content: metaData.fb_locale
        });
      }
      if (metaData.fb_type) {
        meta.push({
          hid: "og:type",
          property: "og:type",
          content: metaData.fb_type
        });
      }
      if (metaData.fb_site_name) {
        meta.push({
          hid: "og:site_name",
          property: "og:site_name",
          content: metaData.fb_site_name
        });
      }
      if (metaData.fb_articles_publisher) {
        meta.push({
          hid: "article:publisher",
          property: "article:publisher",
          content: metaData.fb_articles_publisher
        });
      }
      if (metaData.fb_modified_time) {
        meta.push({
          hid: "article:modified_time",
          property: "article:modified_time",
          content: metaData.fb_modified_time
        });
      }
      if (metaData.twitter_card) {
        meta.push({
          hid: "twitter:card",
          name: "twitter:card",
          content: metaData.twitter_card
        });
      }
      if (metaData.twitter_creator) {
        meta.push({
          hid: "twitter:creator",
          name: "twitter:creator",
          content: metaData.twitter_creator
        });
      }
      if (metaData.twitter_site) {
        meta.push({
          hid: "twitter:site",
          name: "twitter:site",
          content: metaData.twitter_site
        });
      }
      if (metaData.seo_meta_title) {
        meta.push({
          hid: "title",
          name: "title",
          content: metaData.seo_meta_title
        });
      }
      if (metaData.seo_meta_description) {
        meta.push({
          hid: "description",
          name: "description",
          content: metaData.seo_meta_description
        });
      }
      if (metaData.robots) {
        meta.push({
          hid: "robots",
          name: "robots",
          content: metaData.robots
        });
      }
      if (metaData.seo_meta_title) {
        commit("updateMetaTitle", metaData.seo_meta_title);
      } else {
        commit("updateMetaTitle", "Tata Communications");
      }
      if (metaData.canonical) {
        commit(
          "updateCanonicalUrl",
          metaData.canonical.replace(
            "http://beta.tatacommunications.com/",
            "https://www.tatacommunications.com/"
          )
        );
      }
    }
    if (swiftMeta) {
      meta.push(
        {
          class: "swiftype",
          hid: "post_type",
          "data-type": "string",
          name: "post_type",
          content: swiftMeta.post_type
        },
        {
          class: "swiftype",
          hid: "post_title",
          "data-type": "string",
          name: "post_title",
          content: swiftMeta.post_title
        },
        {
          class: "swiftype",
          hid: "image",
          "data-type": "enum",
          name: "image",
          content: swiftMeta.image
        },
        {
          class: "swiftype",
          hid: "description",
          "data-type": "string",
          name: "description",
          content: swiftMeta.description
        },
        {
          class: "swiftype",
          hid: "post_url",
          "data-type": "string",
          name: "post_url",
          content: swiftMeta.url
        }
      );
    }
    if (language_meta) {
      meta.push({
        hid: "x-default",
        rel: "alternate",
        hreflang: "x-default",
        // href: process.env.FRONTEND_URL
        href: swiftMeta.url,
        
      });
      for (let item in language_meta) {
        meta.push({
          hid: language_meta[item].hreflang,
          rel: "alternate",
          hreflang: language_meta[item].hreflang,
          href: language_meta[item].href
        });
      }
    }
    await commit("updateMeta", meta);
  }
};
