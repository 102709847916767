
export default {
  data: () => {
    return {};
  },
  mounted() {
    $(function () {
      if (window.innerWidth > 991) {
        $(".aslider .cardsList").slick({
          slidesToScroll: 1,
          slidesToShow: 4,
          arrows: false,
          infinite: false,
          initialSlide: 1.65,
          centerMode: true,
        });
      }
    });
  },
};
