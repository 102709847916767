
import { Glide, GlideSlide } from "vue-glide-js";
import { section21 } from "~/assets/sliderConfig/section21";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      section21: section21()
    };
  },
  methods: {
    sliderGo(direction) {
      this.$refs.partnerWithSlider.glide.go(direction);
    }
  }
};
