
export default {
  props: {
    breadcrumbData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      structuredData: {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Home",
            item: "https://www.tatacommunications.com/"
          }
        ]
      },
      post_type: this.breadcrumbData.post_type
    };
  },
  head() {
    return {
      script: [
        { type: "application/ld+json", json: this.structuredData, body: true }
      ]
    };
  },
  computed: {
    ancestorsOrder: function () {
      let arr = [];
      if (this.breadcrumbData && this.breadcrumbData.ancestors) {
        let arrLength = this.breadcrumbData.ancestors.length - 1;
        let flag = 0;
        for (let i = arrLength; i >= 0; i--) {
          arr[i] = this.breadcrumbData.ancestors[flag];
          flag++;
        }
        arr.forEach((e, key) => {
          switch (key) {
            case 0:
              {
                if (
                  this.breadcrumbData.post_type === "page" ||
                  this.breadcrumbData.post_type === "solutions" ||
                  this.breadcrumbData.post_type === "innovations" ||
                  this.breadcrumbData.post_type === "sports" ||
                  this.breadcrumbData.post_type === "knowledge-base" ||
                  this.breadcrumbData.post_type === "product"
                ) {
                  e.url = e.slug;
                } else {
                  if (e.slug) {
                    e.url = this.breadcrumbData.post_type + "/" + e.slug;
                  } else {
                    e.url = this.breadcrumbData.post_type;
                  }
                }
              }
              break;
            case 1:
              {
                e.url = arr[key - 1].url + "/" + e.slug;
              }
              break;
            case 2:
              {
                e.url = arr[key - 1].url + "/" + e.slug;
              }
              break;
            case 3:
              {
                e.url = arr[key - 1].url + "/" + e.slug;
              }
              break;
            case 4:
              {
                e.url = arr[key - 1].url + "/" + e.slug;
              }
              break;
            // eslint-disable-next-line no-empty
            default: {
            }
          }
          let itemUrl = "https://www.tatacommunications.com/" + e.url;
          if (key === arr.length - 1) {
            itemUrl = undefined;
          }
          this.structuredData.itemListElement.push({
            "@type": "ListItem",
            position: key + 2,
            name: e.name,
            item: itemUrl
          });
        });
      }
      return arr;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }
      // Here we determine whether we need to show or hide the navbar
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      // Set the current scroll position as the last scroll position
      this.lastScrollPosition = currentScrollPosition;
    }
  }
};
