
import TataKBHeader from "~/components/tataKBHeader.vue";
export default {
  components: { TataKBHeader },
  data: function () {
    return {
      uniqueKey: 0,
      categories: [],
      breadcrumb: []
    };
  },
  watch: {
    $route: async function (to, from) {
      if (to.path !== from.path) this.uniqueKey++; // Adding unique key to nuxt on route change for transitions
      let faq_list = await this.$store.dispatch(
        "faqs/getFaqsList",
        this.$route.params.slug
      );
      this.breadcrumb = faq_list.breadcrumb;
    }
  },
  async fetch() {
    let faq_list = await this.$store.dispatch(
      "faqs/getFaqsList",
      this.$route.params.slug
    );
    this.breadcrumb = faq_list.breadcrumb;

    this.categories = await this.$store.dispatch("faqs/getCategories");
  },
  head() {},
  beforeCreate() {
    this.showLoader = true;
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {},
  mounted() {
    this.showLoader = false;

    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      this.setClickEvent();
    }, 200);
  },
  updated() {
    setTimeout(() => {
      this.setClickEvent();
    }, 200);
  },
  methods: {
    setClickEvent() {
      let faq_lists = document.querySelectorAll(".faq_tabs li p");
      faq_lists.forEach(function (element) {
        if (element.getAttribute("listener") !== "true") {
          element.addEventListener("click", faq_expandClick);
        }
      });

      let __vm = this;
      function faq_expandClick(event) {
        event.currentTarget.setAttribute("listener", "true");

        let slug = event.currentTarget.dataset.slug;
        __vm.$router.push("/faq/" + slug);
      }
    },
    checkInBreadcrumb(slug) {
      let breadcrumb = this.breadcrumb;
      let is_exist = breadcrumb.find(function (ele) {
        return ele.slug == slug;
      });
      return is_exist ? true : false;
    },
    isActive(slug) {
      return slug == this.$route.params.slug;
    },
    gotoSearchPage() {
      this.$router.push("/faq/search");
    }
  }
};
