
export default {
  props: {
    alignStar: {
      type: String,
      default: "justify-content-center"
    },
    starRating: {
      type: Number,
      default: 5
    }
  },
  data: () => {
    return {
      fullStar: "",
      starArr: [],
      maxStar: 5
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
