
import Cookies from "js-cookie";
export default {
  data() {
    return {
      cardData: this.$attrs.cards,
      bannerUrl: "",
      selected_item: {},
      form_id: "3947",
      form_data: {
        form_type: "form1",
        form1_section: {},
        eventData: {
          event: "",
          category: "",
          action: "",
          label: ""
        }
      },
      currentPage: 1,
      totalResult: 0,
      itemsPerPage: 10,
      cookieUpdate: Cookies.get("tc_resource_form") === "Submitted",
      paginatedItems: []
    };
  },
  computed: {
    getFirstCard: function () {
      return this.cardData[0];
    },
    getAllCard: function () {
      let array = this.cardData;
      return array.slice(1);
    },
    totalPages: function () {
      if (this.totalResult === 0) {
        return 1;
      } else {
        return Math.ceil(this.totalResult / this.itemsPerPage);
      }
    },
    /* eslint-disable */
    pagination: function () {
      if (!this.getAllCard) {
        return;
      }
      this.totalResult = this.getFirstCard.length;

      if(this.currentPage >= this.totalPages) {
        this.currentPage = this.totalPages;
      }
      const index = this.currentPage * this.itemsPerPage - this.itemsPerPage;
      this.paginatedItems.push(...this.getAllCard.slice(index, index + this.itemsPerPage));
      return this.paginatedItems;
    },
    unlockedResource() {
      return this.$store.getters["l1/sntCookieUpdate"] === "Submitted";
    }
  },
  beforeCreate() {
    this.$store.commit('l1/updateSntCookiesUpdate', Cookies.get('tc_resource_form'));
  },
  methods: {
    openPopup(item) {
      this.selected_item = item;
      let formSubmitted = Cookies.get("tc_resource_form");
      if (item.has_gated_resource && formSubmitted !== "Submitted") {
        this.$refs["formPopup"].show();
        this.form_id = item.form_id;
        this.form_data.eventData = {
          event: "TataFormSubmit",
          category: "Form submit",
          action: "Popup",
          label: window.location.href
        };
      } else if (item.has_video) {
        this.bannerUrl = item.video_link;
        this.$refs["bannerModal"].show();
      } else {
        const a = document.createElement("a");
        a.setAttribute("href", item.cta.url+"?r=1");
        if (item.cta.open_in === "New Window") {
          a.setAttribute("target", "_blank");
        }
        a.click();
      }
    },
    formSubmitted() {
      this.$refs["formPopup"].hide();
      Cookies.set("tc_resource_form", "Submitted", { expires: 7 });
      this.$store.commit('l1/updateSntCookiesUpdate', "Submitted");
      if (this.selected_item.has_video) {
        setTimeout(() => {
          this.bannerUrl = this.selected_item.video_link;
          this.$refs["bannerModal"].show();
        }, 500);
      } else {
        const a = document.createElement("a");
        a.setAttribute("href", this.selected_item.cta.url+"?r=1");
        if (this.selected_item.cta.open_in === "New Window") {
          a.setAttribute("target", "_blank");
        }
        a.click();
      }
    },
    setNextPage() {
      this.currentPage++;
    },
    // unlockedResource: function (item) {
    //   let formSubmitted = Cookies.get("tc_resource_form");
    //   return (item.has_gated_resource && formSubmitted === "Submitted");
    // }
  }
};
