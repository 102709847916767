
export default {
  data: () => {
    return {
      isActive: false,
      isOverlay: false,
    };
  },
  methods: {
    toggleClass() {
      this.isActive = !this.isActive;
      this.isOverlay = !this.isOverlay;
    }
  }
};
