
export default {
  components: {},
  data: () => {
    return {
      activeTabIndex: 0
    };
  },
  mounted() {},
  methods: {
    changeActiveTab(activeIndex) {
      this.activeTabIndex = activeIndex;
    }
  }
};
