
import { TweenMax } from "gsap";
export default {
  data() {
    return {
      itemsPerRow: 5
    };
  },
  mounted() {
    let parentWidth = document.querySelector(".prodSolutList").offsetWidth;
    let arwList = document.querySelectorAll(".item .arwList");
    arwList.forEach((ele) => {
      ele.style.width = parentWidth - 62 + "px";
    });
  },
  methods: {
    open_accordion(event) {
      let ele = event.currentTarget,
        parent = ele.parentElement,
        insideDom = ele.closest(".inside"),
        innerBox = ele.closest(".innerBox"),
        parentWidth = document.querySelector(".product-solutions__list")
          .offsetWidth;
      if (!insideDom.classList.contains("active")) {
        insideDom.classList.add("active");
        if (window.innerWidth > 1024) {
          TweenMax.to(innerBox, 0.5, { width: parentWidth - 18 + "px" });
        } else {
          // let listHeight = document.
          TweenMax.to(innerBox, 0.5, {
            height: "100%"
          });
        }
        parent.classList.add("active");
      } else {
        insideDom.classList.remove("active");
        parent.classList.remove("active");
        if (window.innerWidth > 1024) {
          TweenMax.to(innerBox, 0.5, { width: insideDom.offsetWidth + "px" });
        }
      }
    },
    groupedAccordion: function (item) {
      if (!item) return;
      return item.reduce((accumulator, awards, index) => {
        if (index % this.itemsPerRow == 0) {
          accumulator.push([awards]);
        } else {
          accumulator[accumulator.length - 1].push(awards);
        }
        return accumulator;
      }, []);
    }
  }
};
