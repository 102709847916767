export const state = () => ({
  contact: []
});
export const mutations = {
  getContactData(state, data) {
    state.contact = data;
  }
};
export const actions = {
  async updateContactData({ commit }) {
    try {
      const res = await this.$axios.get(
        `/page1/?post_type=page&slug=contact-us`,
        {
          useCache: true
        }
      );
      commit("getContactData", res);
      return res;
    } catch (err) {
      console.error(err);
    }
  }
};
