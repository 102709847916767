
import tataArrowLink from "../tata-arrow-link.vue";
import TataLink from "../tata-link.vue";
import LazyVideo from "../tata-lazyload-iframe.vue";
export default {
  components: { tataArrowLink, TataLink, LazyVideo },
  props: {
    eventCode: {
      type: String,
      default: ""
    }
  },
  data: () => {
    return {
      openVideo: false
    };
  },
  mounted() {},
  methods: {
    open() {
      this.openVideo = true;
      const videoDiv = document.getElementById("overviewVideo");
      const videoSrc = videoDiv.src;
      var videoSrcNew = videoSrc + "/?autoplay=1&rel=0&mute=1";
      videoDiv.src = videoSrcNew;
    },
    close() {
      this.openVideo = false;
      const el = document.getElementById("overviewVideo");
      const elSrc = el.src;
      el.src = elSrc;
    },
    openPopup() {
      this.$refs["overviewModal"].show();
    }
  }
};
