
export default {
  data: () => {
    return {};
  },
  mounted() {
    this.loadMarketoForm();
  },
  methods:{
   loadMarketoForm() {
      const script = document.createElement('script');
      script.src = 'https://app-sjp.marketo.com/js/forms2/js/forms2.min.js';
      script.async = true;
      script.onload = () => {
        MktoForms2.loadForm(
          'https://app-sjp.marketo.com',
          '362-YWJ-377',
          6404,
          (form) => {
            MktoForms2.whenReady((form) => {
              // Add an onSuccess handler
              form.onSuccess((values, followUpUrl) => {
                // Get the form's jQuery element and hide it
                form.getFormElem().hide();
                document.getElementById('thankyou').style.display = 'block';
                // Return false to prevent the submission handler from taking the lead to the follow-up URL
                return false;
              });
            });
          }
        );
      };
      document.head.appendChild(script);
    },
  },
   head() {
    return {
        script: [
        {
          src: 'https://app-sjp.marketo.com',
          // Optionally, you can specify other attributes like async, defer, etc.
        },
        {
          src: 'https://app-sjp.marketo.com/js/forms2/js/forms2.min.js',
          // Optionally, you can specify other attributes like async, defer, etc.
        },
      ],
    };
  },
};
