import { render, staticRenderFns } from "./tata-video-cta.vue?vue&type=template&id=573c0da5&scoped=true"
import script from "./tata-video-cta.vue?vue&type=script&lang=js"
export * from "./tata-video-cta.vue?vue&type=script&lang=js"
import style0 from "./tata-video-cta.vue?vue&type=style&index=0&id=573c0da5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573c0da5",
  null
  
)

export default component.exports