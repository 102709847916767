export const state = () => ({
  home_categories: [],
  faq_list: [],
  breadcrumb: [],
});
export const mutations = {
  updateHomeCategories(state, data) {
    state.home_categories = data;
  },
  updateFaqList(state, data) {
    state.faq_list = data;
  },
  updateBreadcrumb(state, data){
    state.breadcrumb = data;
  }
};
export const getters = {
    faqList: (state) => {
      return state.faq_list;
    },
    breadcrumbs: (state)=> {
      return state.breadcrumb;
    }
};
export const actions = {
  async getHomeCategories({ commit }) {
    try {
      let res = await this.$axios.get("/faq/home", {isLoading: false});
      if (res.data.categories) {
        commit("updateHomeCategories", res.data.categories);
        return res.data.categories || [];
      }
    } catch (e) {
      console.error(e);
    }
  },
  async getCategories({commit}) {
    try {
      
      let res = await this.$axios.get('/faq/categories', {isLoading: false, skip_meta: true})
      return res.data
    } catch (error) {
      console.error(e);
    }
  },
  async getFaqsList({commit}, slug) {
    try {
      
      let res = await this.$axios.get(`/faq/list?category_slug=${slug}`)
      if(res.data.faq_list) {
        commit('updateFaqList',res.data.faq_list);
      }
      if(res.data.breadcrumb) {
        commit('updateBreadcrumb',res.data.breadcrumb);
      }
      return res.data;
    } catch (error) {
      console.error(e);
    }
  }
};
