var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"_tata__hyperconnected_reports",class:[
    { 'space__p-t--60 space__p-t-mob--40': _vm.$attrs.top_padding_required },
    { 'space__p-b--60 space__p-b-mob--40': _vm.$attrs.bottom_padding_required }
  ],attrs:{"id":_vm.$attrs.reference_id,"eventCode":_vm.$attrs.event_code}},[_c('div',{staticClass:"_tata__hyperconnected_reports",style:(`background-image: url(${
      _vm.$attrs.background_image ? _vm.$attrs.background_image.url : ''
    })`)},[_c('div',{staticClass:"reports_container"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$attrs.title)}}),_vm._v(" "),_c('h3',{domProps:{"innerHTML":_vm._s(_vm.$attrs.sub_title)}}),_vm._v(" "),(
          _vm.$attrs.cta.text &&
          _vm.$attrs.cta.text !== '' &&
          _vm.$attrs.cta.url &&
          _vm.$attrs.cta.url != ''
        )?_c('tata-primary-cta',{attrs:{"redirect-link":_vm.$attrs.cta.url,"cta-content":_vm.$attrs.cta.text,"target":_vm.$attrs.cta.open_in}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }