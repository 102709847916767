import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { highlight } from "instantsearch.js/es/helpers";
import { connectAutocomplete } from "instantsearch.js/es/connectors";
import { configure } from "instantsearch.js/es/widgets";

export default async (context, inject) => {
  const search = instantsearch({
    indexName: "tata_communcation_live",
    searchClient: algoliasearch(
      "R9I7UBLORR",
      "cb54f2e697020224a974b248b9d6cf84"
    ),
    searchFunction(helper) {
      helper.state.query = helper.state.query.replace(/[^a-zA-Z0-9 ]/g, "");
      // console.log(helper.state.query);
      if (helper.state.query && helper.state.query.length >= 3) {
        helper.search();
      }
    }
  });

  let started = true;

  // Helper for the render function
  const renderIndexListItem = ({ hits }) => `
  <ul class="autocomplete__list overflow-auto">
      ${hits
      .map(
        (hit, index) =>
          `
              <li class="autocomplete__item">
              <a class="autocomplete__item--link"  href="${hit.url}">
                <label>${hit.post_type}</label>
                <p>${highlight({
            attribute: "title",
            highlightedTagName: "em",
            hit
          })}</p>
                </a>
              </li>
            `
      )
      .join("")}
    </ul>
`;

  // create custom widget
  const renderAutocomplete = (renderOptions, isFirstRender) => {
    const { indices, currentRefinement, refine, widgetParams } = renderOptions;
    const autoCompleteList = document.createElement("div");
    const input = document.createElement("input");
    const img = document.createElement("img");

    if (isFirstRender) {
      input.placeholder = "Search...";
      input.classList.add("nav_search-input--text");

      //Autocomplete list
      autoCompleteList.classList.add("autocomplete");

      //Add image
      img.src =
        "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxwYXRoIGZpbGw9IiNjOGM3YzciIGQ9Ik00LjczNiAwYzIuNjA4IDAgNC43MzYgMi4xMjggNC43MzYgNC43MzYgMCAxLjA3Ny0uMzYyIDIuMDc0LS45NzIgMi44NzFsMy4zMTUgMy4zMTVjLjI0Ny4yNDcuMjQ3LjY0NiAwIC44OTNzLS42NTEuMjQ3LS44OTggMEw3LjYwNyA4LjVjLS43OTcuNjEtMS43OTQuOTcyLTIuODcxLjk3MkMyLjEyOCA5LjQ3MiAwIDcuMzQ0IDAgNC43MzYgMCAyLjEyOCAyLjEyOCAwIDQuNzM2IDB6bTAgMS4yNjNjLTEuOTI2IDAtMy40NzMgMS41NDctMy40NzMgMy40NzMgMCAxLjkyNSAxLjU0NyAzLjQ3MyAzLjQ3MyAzLjQ3MyAxLjkyNSAwIDMuNDczLTEuNTQ4IDMuNDczLTMuNDczIDAtMS45MjYtMS41NDgtMy40NzMtMy40NzMtMy40NzN6Ii8+Cjwvc3ZnPgo=";
      img.classList.add("nav__search-input--icon", "d-block");

      input.addEventListener("input", (event) => {
        refine(event.currentTarget.value);
        if (event.currentTarget.value) {
          event.currentTarget.parentElement.classList.add(
            "nav__search-input--has-value"
          );
        } else {
          event.currentTarget.parentElement.classList.remove(
            "nav__search-input--has-value"
          );
        }
      });

      //add all elements in container
      widgetParams.container.innerHTML = "";
      widgetParams.container.appendChild(input);
      widgetParams.container.appendChild(img);
      widgetParams.container.appendChild(autoCompleteList);
    }

    //Hide/Show autocomplete list based on value
    if (
      currentRefinement !== "" &&
      !widgetParams.container
        .querySelector(".autocomplete")
        .classList.contains("d-block")
    ) {
      widgetParams.container
        .querySelector(".autocomplete")
        .classList.add("d-block");
    } else {
      widgetParams.container
        .querySelector(".autocomplete")
        .classList.remove("d-block");
    }

    //Add/Remove active class on input focus & blur
    function inputFocusEvent(event) {
      {
        event.target.parentElement.classList.add("nav__search-input--active");
        if (event.currentTarget.value) {
          event.currentTarget.parentElement.classList.add(
            "nav__search-input--has-value"
          );
        } else {
          event.currentTarget.parentElement.classList.remove(
            "nav__search-input--has-value"
          );
        }
      }
    }

    function inputMouseHoverEvent(event) {
      if (
        currentRefinement !== "" &&
        !widgetParams.container
          .querySelector(".autocomplete")
          .classList.contains("d-block")
      ) {
        widgetParams.container.classList.add("nav__search-input--active");
        widgetParams.container
          .querySelector(".autocomplete")
          .classList.add("d-block");
      }
    }

    input.addEventListener("focus", inputFocusEvent);
    input.addEventListener("blur", (event) => {
      event.target.parentElement.classList.remove("nav__search-input--active");
      event.currentTarget.parentElement.classList.remove(
        "nav__search-input--has-value"
      );
    });

    //autocomplete mouseover event to show/hide list
    widgetParams.container.addEventListener("mouseover", inputMouseHoverEvent);
    widgetParams.container.addEventListener("mouseout", () => {
      if (
        !document.activeElement.classList.contains("nav_search-input--text")
      ) {
        widgetParams.container.classList.remove("nav__search-input--active");
      }

      widgetParams.container
        .querySelector(".autocomplete")
        .classList.remove("d-block");
    });

    //add value in input
    widgetParams.container.querySelector("input").value = currentRefinement;

    widgetParams.container.querySelector(".autocomplete").innerHTML = indices
      .map(renderIndexListItem)
      .join("");

    //Add search more
    widgetParams.container.querySelector(".autocomplete").insertAdjacentHTML(
      "beforeend",
      `<a data-nuxt-link href="/search?q=${currentRefinement}"
        class="autocomplete__result cursor--pointer"><p>See more result for "${currentRefinement}"</p></a>`
    );


    //Nuxt link
    window.addEventListener(
      "click",
      (event) => {
        let target = event.target;

        // If the clicked element doesn't have the right selector, bail
        if (
          !target.matches("a[data-nuxt-link]") &&
          !target.parentElement.matches("a[data-nuxt-link]")
        ) {
          return;
        }
        let path = target.pathname
          ? target.pathname
          : target.parentElement.pathname;

        let search = target.pathname
          ? target.search
          : target.parentElement.search;

        if (path.indexOf("https://www.tatacommunications.com/blog/") >= 0) {
          return;
        }
        // Don't follow the link
        event.preventDefault();

        context.app.router.push(path.replace('https://www.tatacommunications.com', '') + search);

        widgetParams.container.removeEventListener(
          "mouseover",
          inputMouseHoverEvent
        );
      },
      false
    );
    // widgetParams.container.querySelector(".autocomplete").appe

    // Update refine after url change
    let lastUrl = location.href;
    new MutationObserver(() => {
      const url = location.href;
      if (url !== lastUrl) {
        lastUrl = url;
        // refine();
      }
    }).observe(document, { subtree: true, childList: true });
  };

  // Create the custom widget
  const customAutocomplete = connectAutocomplete(renderAutocomplete);


  function initalizedSearch() {
    if (!search.started) {
      search.addWidgets([
        customAutocomplete({
          container: document.querySelector("#nav__search-input")
        }),
        customAutocomplete({
          container: document.querySelector("#mobile_search")
        }),
        configure({
          hitsPerPage: 10
        })
      ]);
      search.start();
    }

  }

  function disposSearch() {
    if (search.started) {

      search.dispose();
    }

  }

  search.on('render', () => {
    started = false;
  });


  // context.$initalizedSearch = initalizedSearch();

  inject('initalizedSearch', initalizedSearch);
  inject('disposSearch', disposSearch);



  // app.router.afterEach((to, from) => {
  //   console.log('sdf');

  //     console.log(';sdj');
  //     // instantiate custom widget
  //   }
  // })
};
