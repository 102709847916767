
export default {
  data: () => {
    return {};
  },
  props: {
    data: {
      type: Object
    }
  },
  mounted() {}
};
