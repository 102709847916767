
export default {
  data() {
    return {
      popupUrl: "",
      openVideo: false
    };
  },
  methods: {
    openPopup(val) {
      this.$refs["bannerModal"].show();
      this.popupUrl = val;
    }
  }
};
