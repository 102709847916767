
import TataLink from "~/components/global-components/tata-link";
import TataArrowLink from "~/components/global-components/tata-arrow-link";
import TataPrimaryCta from "~/components/global-components/tata-primary-cta";
import { Glide, GlideSlide } from "vue-glide-js";

export default {
  components: {
    TataLink,
    TataArrowLink,
    TataPrimaryCta,
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    awardData: {
      type: Object,
      Default: {}
    },
    awardIndex : {
      type: Number,
      default:""
    }
  },
  data: () => {
    return {
      imgSlider: {
        focusAt: 0,
        startAt: 0,
        autoplay: 2000,
        perView: 1,
        type: "carousel",
        dragThreshold: false,
        bullet: false,
        gap: 30,
        breakpoints: {
          992: {
            perView: 1,
            gap: 10,
            type: "carousel"
          }
        }
      },
      modalImgSlider: {
        focusAt: 0,
        startAt: 0,
        autoplay: 2000,
        perView: 1,
        type: "carousel",
        dragThreshold: false,
        bullet: false,
        gap: 30,
        breakpoints: {
          992: {
            perView: 1,
            gap: 10,
            type: "carousel"
          }
        }
      },
      modalSwiper:null,
      currentModalIndex: null,
    };
  },
  methods: {
    showModal(awardIndex){
      this.currentModalIndex = awardIndex;
      this.$refs["awardDetailsModal"].show();
    },
    initializeModalGlide() {
      // Dynamically initialize Glide component after modal is shown
      const awardId = this.awardData.id;
      const awardIndex = this.currentModalIndex;
      const glideSliderRef = `glide_slider_${awardId}_${awardIndex}`;
      
      this.$nextTick(() => {
        // Ensure that Glide component is initialized only when the modal is shown
        const glideComponent = this.$refs[glideSliderRef];
        if (glideComponent && glideComponent.glide) {
          glideComponent.glide.mount();
        }
      });
    },
    modalCurrentSliderChange(ref) {
      this.$nextTick(() => {
        let reference = this.$refs[`glide_slider_${this.awardData.id}_${this.currentModalIndex}`];

        if (reference && reference.$el && reference.$el.parentNode) {
          let currentIndexElement = reference.$el.parentNode.querySelector(".awards-recognition--current-index");

          if (currentIndexElement) {
            currentIndexElement.innerHTML = reference.glide.index + 1;
          }
        }
      });
    },
    readMore: function (event) {
      var target = event.target;
      target.closest(`li`).classList.add("active");
      target.closest(".read-more").classList.add("d-none");
      document.querySelector(".awards-recognition").classList.add("active");

      if (document.documentElement.clientWidth >= 992) {
        let boxHeight = target
          .closest("li")
          .querySelector(".awards-recognition--list-item__aside").offsetHeight;

        target
          .closest("li")
          .querySelector(
            ".awards-recognition--list-item--details__inner .editor-data"
          ).style.height = boxHeight - 90 + "px";
      }
    },
    detailsClose: function (event) {
      var target = event.target;
      target.closest(`li`).classList.remove("active");
      target
        .closest(".awards-recognition--list-item__aside")
        .querySelector(".read-more")
        .classList.remove("d-none");
      document.querySelector(".awards-recognition").classList.remove("active");
    },
    currentSliderChange(ref) {
      let reference = this.$refs[`glide_slider_${this.awardData.id}`];
      reference.$el.parentNode.querySelector(
        ".awards-recognition--current-index"
      ).innerHTML = reference.glide.index + 1;
    }
  },
  mounted(){
    // Extract ID from URL
    const hash = window.location.hash;
    const idFromUrl = hash ? hash.substring(1) : null;
    // Check if idFromUrl is null
    if (idFromUrl === null) {
        return; 
    }
    const sectionId = idFromUrl;

    // Apply offset if needed
    const element = document.getElementById(sectionId);
    setTimeout(() => {
      if (element) {
        const yOffset = 200; // Change this value to your desired offset
        const yCoordinate = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: yCoordinate - yOffset, behavior: 'smooth' });
      }
    }, 1000);
  }
};
