
export default {
  data: () => {
    return {
      activeIndex: 0,
      activeTabIndex: 0,
      visible: false,
      active: false,
      key: "",
      isActive: false,
      isDropdownOpen: false
    };
  },
  mounted() {
    // Create a new script element
    var scriptElement = document.createElement("script");
    scriptElement.src =
      "https://tatacommunications.uberflip.com/hubsFront/embed_collection";

    // Function to append the script to the head tag
    function appendScriptToHead() {
      // Get the head element
      var headElement =
        document.head || document.getElementsByTagName("head")[0];

      // Append the script element to the head
      headElement.appendChild(scriptElement);
    }

    // Attach the function to the window's onload event
    if (window.addEventListener) {
      window.addEventListener("load", appendScriptToHead, false);
    } else if (window.attachEvent) {
      window.attachEvent("onload", appendScriptToHead);
    } else {
      window.onload = appendScriptToHead;
    }
  },
  computed: {},
  methods: {
    changeTab(index) {
      this.activeIndex = index;
    },
    selectTab(index) {
      this.activeIndex = index;
      this.toggleDropdown();
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    }
  }
};
