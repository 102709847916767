
  import { Glide, GlideSlide } from "vue-glide-js";
  import AwardDetails from "~/components/global-components/award-details";
  export default {
    components: {
      AwardDetails,
      [Glide.name]: Glide,
      [GlideSlide.name]: GlideSlide
    },
    data: () => {
      return {
        filter: {
          analyst_firm: [],
          position:[],
          portfolio:[],
          business_impact:[]
        },
        slider_model: [],
        years: [],
        activeTab: 0,
        allAwards: [],
        filteredAwards: [],
        filteredData:[],
        filteredYear: [],
        categories: [],
        bannerSlider: {
          items: [],
          isAwards:true,
        },
        yearSwiper:null,
      };
    },
    async fetch() {
      let { store } = this.$nuxt.context;
      const awards = await store.dispatch("awards-new/getAllAwards");
      awards?.featured_analyst_award.forEach((ele) => {
        let image = {
          src: "https://gamma.tatacommunications.com/assets/wp-content/uploads/2021/12/Awards-Image.jpeg"
        };
        if (ele.images) {
          image.src = ele.images[0].image.url;
        }
  
        this.bannerSlider.items.push({
          title: ele.title,
          sub_title: ele.subtitle,
          featured_image: image,
          slug: ele.cta ? ele.cta.url : "",
          slug_title: ele.cta ? ele.cta.title : "",
          has_featured_image: true
        });
      });
      this.allAwards = this.filteredAwards = awards?.all_analyst_award;
      this.categories = store.getters["awards-new/allCategories"]; 
      if(!this.allAwards) return; 
      this.years = this.filteredYear = Object.keys(this.allAwards).sort(function (a,b) {
        return b - a;
      });
    },
    watch: {
        filter: {
          deep: true,
          handler({ analyst_firm, position , portfolio ,business_impact }) {
            let filterd_array = [];
            if (analyst_firm.length || position.length || portfolio.length || business_impact.length) {
              filterd_array = [];
              if(!this.allAwards) return;
              Object.keys(this.allAwards).filter((year) => {
                this.allAwards[year].filter((ele) => {
                  if (ele.analyst_firm) {
                    ele.analyst_firm.forEach((item) => {
                      if (analyst_firm.includes(item.term_id)) {
                        if (!filterd_array[year]) {
                          filterd_array[year] = [];
                        }
                        filterd_array[year].push(ele);
                      }
                    });
                  }
                  if(ele.position){
                    ele.position.forEach((item) => {
                      if (position.includes(item.term_id)) {
                        if (!filterd_array[year]) {
                          filterd_array[year] = [];
                        }
                        filterd_array[year].push(ele);
                      }
                    });
                  }
                  if(ele.portfolio){
                    ele.portfolio.forEach((item) => {
                      if (portfolio.includes(item.term_id)) {
                        if (!filterd_array[year]) {
                          filterd_array[year] = [];
                        }
                        filterd_array[year].push(ele);
                      }
                    });
                  }
                  if(ele.business_impact){
                    ele.business_impact.forEach((item) => {
                      if (business_impact.includes(item.term_id)) {
                        if (!filterd_array[year]) {
                          filterd_array[year] = [];
                        }
                        filterd_array[year].push(ele);
                      }
                    });
                  }
                });
              });
              this.filteredYear = [];
              if(!filterd_array) return;
              this.filteredYear = Object.keys(filterd_array).sort((a, b) => {
                return b - a;
              });
              this.filteredAwards = filterd_array;
              console.log("filteredAwards",this.filteredAwards);
            } else {
              this.filteredAwards = this.allAwards;
              this.filteredYear = this.years; 
            } 
          }
      }
    },
    mounted() {
      this.initializeSwiper()
      document.addEventListener("keydown", function (event) {
        if (event.key === "Escape") {
          document.querySelector(".awards-recognition--list-item.active").classList.remove("active");
          document.querySelector(".awards-recognition").classList.remove("active");
          document.querySelector(".read-more").classList.remove("d-none");
        }
      });
    },
    methods: {
        initializeSwiper() {
            if (typeof Swiper !== 'undefined') {
                this.yearSwiper = new Swiper(".mySwiper", {
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                });
            } else {
                console.log('Swiper is not defined');
            }
        },
      openNCloseFilter() {
        let filter = this.$refs.filterList;
        if (filter.classList.contains("d-block")) {
          filter.classList.remove("d-block");
        } else {
          filter.classList.add("d-block");
        }
      },
      *chunks(arr, n) {
        for (let i = 0; i < arr.length; i += n) {
          yield arr.slice(i, i + n);
        }
      },
    }
  };
  