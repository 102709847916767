
export default {
  props: {
    activeTabIndex: {
      type: Number,
      default: 1
    }
  },
  data: () => {
    return {
      activeTab: -1,
      isTabClicked: false
    };
  },
  mounted() {},
  methods: {}
};
