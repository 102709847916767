
export default {
  data() {
    return {
      email: "",
      is_email_submit: false,
      otp: "",
      is_otp_submit: false,
      form_data: false,
      loading: ""
    };
  },
  async fetch() {
    try {
      let searchMeta = {
        metaData: {
          fb_title: `Preference Center | Tata Communications`,
          fb_image: "~/assets/img/Tata-Communications-App-Icon.png",
          fb_image_width: 450,
          fb_image_height: 450,
          twitter_title: `Preference Center | Tata Communications`,
          seo_meta_title: `Preference Center | Tata Communications`,
          robots: "noindex,follow"
        }
      };
      this.$store.dispatch("meta/updateMeta", searchMeta);
    } catch (error) {
      console.error(error);
    }
  },
  watch: {
    email(value) {
      if (value) {
        this.error.email = "";
      }
    },
    otp(value) {
      if (value) {
        this.error.otp = "";
      }
    }
  },
  mounted() {
    if (!process.server) {
      const script = document.createElement("script");
      script.onload = this.loadMarktoForm;
      script.type = "text/javascript";
      script.src =
        "https://info.tatacommunications.com/js/forms2/js/forms2.min.js";
      document.head.appendChild(script);
    } else {
      this.loadMarktoForm();
    }
    this.$store.commit("l1/updatePageTitle", "Preference Center", {
      root: true
    });
  },
  methods: {
    loadMarktoForm() {
        let form_id = this.$attrs.form_id;
      if (window) {
        window.MktoForms2.loadForm(
          "//info.tatacommunications.com",
          "362-YWJ-377",
          form_id,
          function (form) {
            form.onSuccess(function (value) {
              return false;
            });
          }
        );
        window.MktoForms2.whenReady(function (form) {
          let formElement = document.querySelector("#mktoForm_2438");
          let formRows = formElement.querySelectorAll(".mktoFormRow fieldset");
          for (const formRowsKey in formRows) {
            let fieldSet = formRows[formRowsKey];
            if (fieldSet instanceof Element) {
              fieldSet.querySelector("legend").classList.add("heading");
              fieldSet
                .querySelectorAll(".mktoFormRow")
                .forEach((el, index, array) => {
                  el.classList.add("checkbox");
                  if ((index + 1) % 2 != 0) {
                    el.classList.add("even");
                  } else {
                    el.classList.add("odd");
                  }

                  if (array.length - 1 === index) {
                    el.classList.add("last-child");
                  }
                });
            }
          }

          let formRowsInputs = formElement.querySelectorAll(
            "#mktoForm_2438 > .mktoFormRow "
          );
          for (const key in formRowsInputs) {
            if (formRowsInputs[key] instanceof Element) {
              formRowsInputs[key].classList.add("row");

              formRowsInputs[key]
                .querySelectorAll(":scope > .mktoFieldWrap ")
                .forEach((el) => {
                  if (key == 1 || key == 2 || key == 2) {
                    el.classList.add("col-md-12");
                  } else {
                    el.classList.add("col-md-6");
                  }
                });
            }
          }

          let formRowsButton = formElement.querySelector(".mktoButtonRow");
          formRowsButton.classList.add(
            "row",
            "justify-content-center",
            "d-flex"
          );
          formRowsButton
            .querySelector(".mktoButtonWrap")
            .classList.add("col-md-4", "col-12", "d-block");

          let inputFields = formElement.getElementsByClassName("mktoField");

          for (const inputFieldsKey of inputFields) {
            if (
              inputFieldsKey.type !== "checkbox" &&
              inputFieldsKey.type !== "hidden"
            ) {
              let label_value = inputFieldsKey.value;
              if (inputFieldsKey.type === "select-one") {
                label_value = inputFieldsKey.selectedOptions[0].text;
                inputFieldsKey.selectedOptions[0].text = "Select...";
                inputFieldsKey.parentNode.classList.add("active");
              }
              inputFieldsKey.parentNode.querySelector("label").innerHTML =
                inputFieldsKey.parentNode.querySelector("label").innerHTML +
                label_value;
              inputFieldsKey.value = "";
              inputFieldsKey.addEventListener("focus", function (e) {
                inputFieldsKey.parentNode.classList.add("active");
                e.target.setAttribute("focusListener", "true");
              });

              if (inputFieldsKey.getAttribute("blurListener") !== "true") {
                inputFieldsKey.addEventListener("blur", function (e) {
                  if (!inputFieldsKey.value && e.target.type !== "select-one") {
                    inputFieldsKey.parentNode.classList.remove("active");
                  }
                });
              }
            }
          }
        });
      }
    }
  }
};
