
  export default {
    props: {
      id: {
        type: Number,
        default: 1
      },
      cardData: {
        type: Object,
        default: null
      }
    }
  };
  