export const state = () => ({
  all_categories: []
});
export const mutations = {
  updateAllCategories(state, data) {
    state.all_categories = data;
  }
};
export const getters = {};
export const actions = {
  async getAllCategories({ commit }, param = { skip_meta: false }) {
    try {
      let res = await this.$axios.get("/knowledge-base/categories", {
        isLoading: true,
        skip_meta: param.skip_meta || false
      });
      if (res.data.categories) {
        commit("updateAllCategories", res.data.categories);
        return res.data.categories || [];
      }
    } catch (e) {
      console.error(e);
    }
  },
  async getPostByCategories({}, params) {
    try {
      let res = await this.$axios.get(
        `/knowledge-base/category?slug=${params.slug}`,
        {
          isLoading: true
        }
      );
      if (res.data) {
        return res.data || [];
      }
    } catch (e) {
      console.error(e);
    }
  },
  async getPostByTag({}, params) {
    try {
      let res = await this.$axios.get(
        `/knowledge-base/tag?slug=${params.slug}`,
        {
          isLoading: true
        }
      );
      if (res.data) {
        return res.data || [];
      }
    } catch (e) {
      console.error(e);
    }
  },
  async getPostBySlug({}, params) {
    try {
      let res = await this.$axios.get(
        `/knowledge-base?slug=${params.slug}&category=${params.category}`,
        {
          isLoading: true
        }
      );
      return res.data;
    } catch (e) {
      console.error(e);
    }
  }
};
