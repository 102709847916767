
import { isDesktop } from "~/utils/device";

export default {
  props: {
    activeTabIndex: {
      type: Number,
      default: 1
    }
  },
  data: () => {
    return {
      activeTab: -1,
      isTabClicked: false
    };
  },
  created(){
    if (process.client && isDesktop() && this.$attrs.add_sticky_nav_section) {
      window.addEventListener("scroll", this.handleNavScroll);
    }
  },
  destroyed(){
    if (process.client && isDesktop() && this.$attrs.add_sticky_nav_section) {
      window.removeEventListener("scroll", this.handleNavScroll);
    }
  },
  mounted() {},
  methods: {
    handleNavScroll() {
      if (this.$route.path != '/') {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const header = document.querySelector('._tata__header_new');
        const logoSearchContainer = document.querySelector('.logoSearchContainer');
        const mainAndSubNavContainer = document.querySelector('.mainAndSubNavContainer');
        const customNavComponent = document.querySelector('._tata__sticky-navigation');
        const footer = document.querySelector('._tata__footer');

        if (scrollTop > 300) {
          header.style.padding = '0';
          logoSearchContainer.style.transition = 'opacity 0.2s';
          logoSearchContainer.style.opacity = '0';
          logoSearchContainer.style.display = 'none';
        } else {
          header.style.padding = '14px 0 0 0';
          logoSearchContainer.style.transition = 'opacity 0.2s';
          logoSearchContainer.style.opacity = '1';
          logoSearchContainer.style.display = 'block';
        }

        if (scrollTop > 750) {
          mainAndSubNavContainer.style.transition = 'opacity 0.2s';
          mainAndSubNavContainer.style.opacity = '0';
          mainAndSubNavContainer.style.setProperty('display', 'none', 'important');
          if (customNavComponent) {
            customNavComponent.style.top = '0px';
          }
        } else {
          mainAndSubNavContainer.style.transition = 'opacity 0.2s';
          mainAndSubNavContainer.style.opacity = '1';
          mainAndSubNavContainer.style.setProperty('display', 'block', 'important');
          if (customNavComponent) {
            customNavComponent.style.top = '55px';
          }
        }

        if (footer) {
          const footerRect = footer.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

          if (footerRect.top <= viewportHeight / 2) {
            header.style.padding = '14px 0 0 0';
            logoSearchContainer.style.transition = 'opacity 0.2s';
            logoSearchContainer.style.opacity = '1';
            logoSearchContainer.style.display = 'block';
            mainAndSubNavContainer.style.transition = 'opacity 0.2s';
            mainAndSubNavContainer.style.opacity = '1';
            mainAndSubNavContainer.style.setProperty('display', 'block', 'important');
          }
        }
      }
    }
  }
};
