
export default {
  data() {
    return {
      feedback: true
    };
  },
  mounted() {
    if (process.client) {
      let channel_id = this.$attrs.channel_id,
        webinar_id = this.$attrs.webinar_id;
      let ScriptTag = document.createElement("script");
      ScriptTag.type = "application/json";
      ScriptTag.className = "jsBrightTALKEmbedConfig";
      let scriptText = document.createTextNode(
        '{ "channelId": ' +
          channel_id +
          ' , "language": "en-US", "commId": ' +
          webinar_id +
          ', "displayMode": "standalone", "height": "auto"}'
      );
      ScriptTag.appendChild(scriptText);
      let brightTalkDiv = document.querySelector(".jsBrightTALKEmbedWrapper");
      brightTalkDiv.appendChild(ScriptTag);
      let ScriptTag2 = document.createElement("script");
      ScriptTag2.src =
        "https://www.brighttalk.com/clients/js/player-embed/player-embed.js";
      ScriptTag2.className = "jsBrightTALKEmbed";
      brightTalkDiv.appendChild(ScriptTag2);
    }
  },
  methods: {
    submitFeedback(answer) {
      this.$gtm.push({
        event: this.$attrs.feedback.google_event_name,
        eventCategory: this.$attrs.feedback.google_event_category,
        eventAction: answer,
        eventLabel: this.$attrs.feedback.google_event_label
      });
      this.feedback = false;
    }
  }
};
