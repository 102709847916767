import { cacheAdapterEnhancer } from "axios-extensions";
import LRUCache from "lru-cache";

const ONE_HOUR = 1000 * 60 * 60;
export default function ({ $axios, store, route, req, error }) {
  const defaultCache = new LRUCache({ maxAge: ONE_HOUR });
  const defaults = $axios.defaults;
  defaults.adapter = cacheAdapterEnhancer(
    defaults.adapter,
    false,
    "useCache",
    defaultCache
  );
  if (process.server) {
    store.commit("baseUrl", req.headers.host);
  }
  $axios.onError(async (responseError) => {
    responseError = {
      ...responseError
    };
    let returnVal = false;
    // let redir = true;
    const res = responseError?.response || { status: 500 };
    store.commit("updateGlobalLoader", false);

    if (responseError?.response?.status?.toString()?.indexOf("4") > -1) {
      error({ statusCode: 404, message: "Post not found from interceptor" });
    }
    if (res && res?.status === 500) {
      error({ statusCode: 500, message: "Post not found from interceptor" });
    }
    return Promise.resolve(returnVal);
  });
  $axios.onRequest((req) => {
    if (process.server) {
      req.headers = { "User-Agent": "app-server" };
    }
    if (process.browser) {
      if (req.scrollTo) {
        window.scrollTo(0, 0);
      }
    }
    if (req.isLoading) {
      store.commit("updateGlobalLoader", true);
    }
  });
  $axios.onResponse((response) => {
    store.commit("updateGlobalLoader", false);
    let metaPushData = { metaData: {}, swiftMeta: {} };
    let updateMeta = false;
    if (response.data) {
      let data = JSON.stringify(response.data);
      data = data.replace(
        /https:\/\/beta.tatacommunications.com\/wp-content\//g,
        "https://gamma.tatacommunications.com/assets/wp-content/"
      );
      response.data = JSON.parse(data);
    }
    if (response.data && response.data.data && response.data.data.breadscrum) {
      store.commit("l1/updateBreadcrumbs", response.data.data.breadscrum);
    }
    if (response.data && response.data.data && response.data.data.meta) {
      metaPushData.metaData = response.data.data.meta;
      updateMeta = true;
    }
    if (
      response.data &&
      response.data.data &&
      response.data.data.swiftype_search_meta
    ) {
      let meta = response.data.data.swiftype_search_meta;
      meta.url = store.state.baseUrl + route.path;
      metaPushData.swiftMeta = meta;
      updateMeta = true;
    }
    if (
      response.data &&
      response.data.data &&
      response.data.data.language_meta
    ) {
      metaPushData.language_meta = response.data.data.language_meta;
      updateMeta = true;
    }
    if (updateMeta && !response.config.skip_meta) {
      store.dispatch("meta/updateMeta", metaPushData);
    }

    return response.data;
  });
}
