
import { Glide, GlideSlide } from "vue-glide-js";
import { pressReleaseCarousel } from "~/assets/sliderConfig/pressReleaseCarousel";
import { caseStudyCarousel } from "~/assets/sliderConfig/caseStudyCarousel";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      currentSlideIndex: 0,
      pressReleaseCarousel: pressReleaseCarousel(),
      caseStudyCarousel: caseStudyCarousel(),
      fullQuote: null
    };
  },
  computed: {},
  methods: {
    moveSlide(args) {
      if (!args) {
        this.$refs.slider.glide.go("<");
        //this.$refs.sliderimages.glide.go("<");
      } else {
        this.$refs.slider.glide.go(">");
        // this.$refs.sliderimages.glide.go(">");
      }
      this.currentSlideIndex = this.$refs.slider.glide.index;
    },
    openFullQuotes(val) {
      this.$refs["quote"].show();
      this.fullQuote = val;
    }
  }
};
