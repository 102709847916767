export const section2 = () => {
  return {
    focusAt: 0,
    startAt: 0,
    autoplay: 7000,
    perView: 1,
    type: "carousel",
    dragThreshold: false,
    bullet: true,
    gap: 30,
    breakpoints: {
      992: {
        perView: 1,
        gap: 10,
        type: "carousel"
      }
    }
  };
};
