
import TataLink from "./tata-link.vue";
export default {
  components: { TataLink },
  data: () => {
    return {
      showLoader: false,
      categories: []
    };
  },
  async fetch() {
    try {
      let item_specific_data = {
        should_the_api_for_this_page_or_item_have_header_and_footer_menu: false,
        custom_css: "",
        contact_us_cta: {
          text: "Contact Us",
          url: "/contact-us",
          open_in: "New Window"
        },
        secondary_menu_list: [],
        show_secondary_menu_on_this_page: "yes",
        show_custom_links: "no"
      };
      this.$store.commit("l1/updatePageTitle", "FAQ Repository");
      this.$store.commit("l1/updateItemSpecificData", item_specific_data);
      this.categories = await this.$store.dispatch("faqs/getHomeCategories");
    } catch (error) {
      // console.error(error);
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: "en"
      },
      title: this.$store.getters["meta/metaTitle"],
      meta: this.$store.getters["meta/meta"],
      link: [
        {
          rel: "canonical",
          href: this.$store.getters["meta/canonicalUrl"]
            ? this.$store.getters["meta/canonicalUrl"]
            : "https://www.tatacommunications.com" + this.$route.fullPath
        }
      ]
    };
  },
  beforeCreate() {
    this.showLoader = true;
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.showLoader = false;

    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  methods: {
    gotoSearchPage() {
      this.$router.push("/faq/search");
    }
  }
};
