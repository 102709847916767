
export default {
  data: () => {
    return {
      currentActive: 0
    };
  },
  mounted() {},
  methods: {
    filteredProduct(category) {
      return this.$attrs.list.filter((ele) => {
        return ele.product.includes(parseInt(category));
      });
    }
  }
};
