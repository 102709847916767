
import { Glide, GlideSlide } from "vue-glide-js";
import { section2 } from "~/assets/sliderConfig/section2";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    tataBanner: () => import("@/components/global-components/tata-banner.vue")
  },
  data: () => {
    return {
      section2: section2(),
      bannerUrl: ""
    };
  },
  methods: {
    showBannerModal(val) {
      this.$refs["bannerModal"].show();
      this.bannerUrl = val;
    }
  }
};
