
export default {
  props: {
    width: {
      type: String,
      default: "0"
    },
    height: {
      type: String,
      default: "0"
    }
  },
  computed: {
    cwidth: function () {
      return this.width;
    },
    cheight: function () {
      return this.height;
    }
  }
};
