export const videoCarousel = () => {
    return {
        type: "swipe",
        perView: 3.1,
        focusAt: 0,        // Start focusing from the first slide
        rewind: false,
        bullet: false,
        gap: 15,
        breakpoints: {
            992: {
                perView: 1.1,
                focusAt: 0,        // Start focusing from the first slide
                rewind: false,
            }
        }
    };
};