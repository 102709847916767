
export default {
  props: {
    modifiers: {
      type: String,
      default: ""
    },
    ctaContent: {
      type: String,
      default: ""
    },
    ctaUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      bannerUrl: null
    };
  },
  mounted() {},
  methods: {
    showBannerModal(val) {
      this.$refs["bannerModal"].show();
      this.bannerUrl = val;
    }
  }
};
