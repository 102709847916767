import { render, staticRenderFns } from "./faqs-inner.vue?vue&type=template&id=6df31f06"
import script from "./faqs-inner.vue?vue&type=script&lang=js"
export * from "./faqs-inner.vue?vue&type=script&lang=js"
import style0 from "./faqs-inner.vue?vue&type=style&index=0&id=6df31f06&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TataKBHeader: require('/home/clouduser/tc-nuxt/components/tataKBHeader.vue').default,TataFooterFaqs: require('/home/clouduser/tc-nuxt/components/tataFooterFaqs.vue').default})
