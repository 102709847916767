export const twitterCardCarousel = () => {
  return {
    perView: 3,
    type: "carousel",
    bullet: true,
    gap: 30,
    breakpoints: {
      640: {
        perView: 1
      }
    }
  };
};
