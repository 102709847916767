
import { mapState, mapGetters } from "vuex";
export default {
  data: () => {
    return {
      structuredData: {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: []
      }
    };
  },
  head() {
    return {
      script: [
        { type: "application/ld+json", json: this.structuredData, body: true }
      ]
    };
  },
  computed: {
    ...mapState(["menuData"]),
    ...mapState("l1", ["itemSpecificData"]),
    ...mapGetters("l1", ["breadcrumbs"]),
    footerData: function () {
      return this.menuData;
    },
    async fetch() {
      try {
       await this.$store.dispatch("updateMenu");
      } catch (error) {
        console.log(error);
      }
    },
    firstHalf() {
      if (
        this.footerData &&
        this.footerData.footer_menus &&
        this.footerData.footer_menus.length &&
        this.footerData.footer_menus.length > 4
      ) {
        const element = this.footerData.footer_menus[3];
        // debugger;
        return this.splitArray(element.children, 2)[0];
      } else {
        return [];
      }
    },
    secondHalf() {
      if (
        this.footerData &&
        this.footerData.footer_menus &&
        this.footerData.footer_menus.length &&
        this.footerData.footer_menus.length > 4
      ) {
        const element = this.footerData.footer_menus[3];
        // debugger;
        return this.splitArray(element.children, 2)[1];
      } else {
        return [];
      }
    },
    ancestorsOrder: function () {
      let arr = [];
      if (this.breadcrumbs && this.breadcrumbs.ancestors) {
        let arrLength = this.breadcrumbs.ancestors.length - 1;
        let flag = 0;
        for (let i = arrLength; i >= 0; i--) {
          arr[i] = this.breadcrumbs.ancestors[flag];
          flag++;
        }
        this.structuredData.itemListElement = new Array();
        this.structuredData.itemListElement.push({
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: "https://www.tatacommunications.com/"
        });
        arr.forEach((e, key) => {
          switch (key) {
            case 0:
              {
                if (
                  this.breadcrumbs.post_type === "page" ||
                  this.breadcrumbs.post_type === "solutions" ||
                  this.breadcrumbs.post_type === "innovations" ||
                  this.breadcrumbs.post_type === "sports" ||
                  this.breadcrumbs.post_type === "knowledge-base" ||
                  this.breadcrumbs.post_type === "product" ||
                  this.breadcrumbs.post_type === "digital-futures"
                ) {
                  if (this.breadcrumbs.post_type === "product") {
                    e.url = "products";
                  } else if (this.breadcrumbs.post_type === "innovations") {
                    e.url = "innovation";
                  } else {
                    e.url = e.slug;
                  }
                } else {
                  if (e.slug) {
                    e.url = this.breadcrumbs.post_type + "/" + e.slug;
                  } else {
                    e.url = this.breadcrumbs.post_type;
                  }
                }
              }
              break;
            case 1:
              {
                if (e.slug) e.url = arr[key - 1].url + "/" + e.slug;
              }
              break;
            case 2:
              {
                if (e.slug) e.url = arr[key - 1].url + "/" + e.slug;
              }
              break;
            case 3:
              {
                if (e.slug) e.url = arr[key - 1].url + "/" + e.slug;
              }
              break;
            case 4:
              {
                if (e.slug) e.url = arr[key - 1].url + "/" + e.slug;
              }
              break;
            // eslint-disable-next-line no-empty
            default: {
            }
          }
          let itemUrl = "https://www.tatacommunications.com/" + e.url;
          if (key === arr.length - 1) {
            itemUrl = undefined;
          }
          this.structuredData.itemListElement.push({
            "@type": "ListItem",
            position: key + 2,
            name: e.name,
            item: itemUrl
          });
        });
      }
      return arr;
    }
  },
  mounted() {},
  methods: {
    splitArray(flatArray, numCols) {
      const newArray = [];
      for (let c = 0; c < numCols; c++) {
        newArray.push([]);
      }
      for (let i = 0; i < flatArray.length; i++) {
        const mod = i % numCols;
        newArray[mod].push(flatArray[i]);
      }
      return newArray;
    },
    isCountryPage() {
      return this.itemSpecificData &&
        this.itemSpecificData
          .should_the_api_for_this_page_or_item_have_header_and_footer_menu
        ? this.itemSpecificData
            .should_the_api_for_this_page_or_item_have_header_and_footer_menu
        : false;
    }
  }
};
