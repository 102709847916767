
export default {
  data() {
    return {
      // filteredData: [],
      paginationList: 9,
      allItems: [],
      filterItem: [],
      bannerUrl: "",
      filter: {
        featured_customer: false,
        products: [],
        region: [],
        industry: [],
        asset: [],
        search: ""
      },
      activeTab: 'Case Studies',
      caseStudyData:[],
      ourCustomerData:[],
      persistData:[],
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    filterData: function () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if(this.activeTab === 'Case Studies'){
        this.persistData=[];
        this.persistData = this.caseStudyData;
        this.paginationList = 9;
        if (
          !this.filter.featured_customer &&
          this.filter.products.length &&
          this.filter.region.length &&
          this.filter.industry.length &&
          this.filter.asset.length &&
          !this.filter.search
        ) {
          return this.persistData;
        } else {
          return this.persistData.filter((ele) => {
            let returnFeaturedCustomer = true;
            let returnProduct = true;
            let returnRegion = true;
            let returnIndustry = true;
            let returnAsset = true;
            let returnSearch = true;

            if (this.filter.featured_customer) {
              returnFeaturedCustomer = ele.is_featured_customer;
            }

            if (this.filter.products.length) {
              let productList = ele.taxo.product.map((ele) => {
                return ele.term_id;
              });
              returnProduct = !this.filter.products.every((e) => {
                return !productList.includes(e);
              });
            }
            if (this.filter.region.length) {
              let regionList = ele.taxo.region.map((ele) => {
                return ele.term_id;
              });
              returnRegion = !this.filter.region.every((e) => {
                return !regionList.includes(e);
              });
            }
            if (this.filter.industry.length) {
              let industryList = ele.taxo.industry.map((ele) => {
                return ele.term_id;
              });
              returnIndustry = !this.filter.industry.every((e) => {
                return !industryList.includes(e);
              });
            }
            if (this.filter.asset.length) {
              let assetList = ele.taxo.asset.map((ele) => {
                return ele.term_id;
              });
              returnAsset = !this.filter.asset.every((e) => {
                return !assetList.includes(e);
              });
            }
            if (this.filter.search) {
              returnSearch =
                ele.title
                  .toLowerCase()
                  .indexOf(this.filter.search.toLowerCase()) > -1;
            }

            return (
              returnFeaturedCustomer &&
              returnProduct &&
              returnIndustry &&
              returnRegion &&
              returnAsset &&
              returnSearch
            );
          });
        }
      }
      if(this.activeTab === 'Our Customers'){
        this.persistData=[];
        this.persistData = this.ourCustomerData;
        this.paginationList = 9;
        if (
          !this.filter.featured_customer &&
          this.filter.products.length &&
          this.filter.region.length &&
          this.filter.industry.length &&
          this.filter.asset.length &&
          !this.filter.search
        ) {
          return this.persistData;
        } else {
          return this.persistData.filter((ele) => {
            let returnFeaturedCustomer = true;
            let returnProduct = true;
            let returnRegion = true;
            let returnIndustry = true;
            let returnAsset = true;
            let returnSearch = true;

            if (this.filter.featured_customer) {
              returnFeaturedCustomer = ele.is_featured_customer;
            }

            if (this.filter.products.length) {
              let productList = ele.taxo.product.map((ele) => {
                return ele.term_id;
              });
              returnProduct = !this.filter.products.every((e) => {
                return !productList.includes(e);
              });
            }
            if (this.filter.region.length) {
              let regionList = ele.taxo.region.map((ele) => {
                return ele.term_id;
              });
              returnRegion = !this.filter.region.every((e) => {
                return !regionList.includes(e);
              });
            }
            if (this.filter.industry.length) {
              let industryList = ele.taxo.industry.map((ele) => {
                return ele.term_id;
              });
              returnIndustry = !this.filter.industry.every((e) => {
                return !industryList.includes(e);
              });
            }
            if (this.filter.asset.length) {
              let assetList = ele.taxo.asset.map((ele) => {
                return ele.term_id;
              });
              returnAsset = !this.filter.asset.every((e) => {
                return !assetList.includes(e);
              });
            }
            if (this.filter.search) {
              returnSearch =
                ele.title
                  .toLowerCase()
                  .indexOf(this.filter.search.toLowerCase()) > -1;
            }

            return (
              returnFeaturedCustomer &&
              returnProduct &&
              returnIndustry &&
              returnRegion &&
              returnAsset &&
              returnSearch
            );
          });
        }
      }
    }
  },
  watch:{
    activeTab : function (value) {
      this.filter.featured_customer=false;
      this.filter.products= [];
      this.filter.region= [];
      this.filter.industry= [];
      this.filter.asset= [];
      this.filter.search= "";
    }
  },

  mounted() {
    this.filter.featured_customer = false;
    this.allItems = this.$attrs.items;
    this.filterItem = this.$attrs.taxonomies;
    let caseStudyData = this.allItems.filter(item => {
      return item.taxo.tab.name === 'Case Study Tab';
    });
    this.caseStudyData = caseStudyData;

    let ourCustomerData = this.allItems.filter(item => {
      return item.taxo.tab.name === 'Our Customers Tab';
    });
    this.ourCustomerData = ourCustomerData;
  },
  methods: {
    urlLink(args) {
      if (args.indexOf("http://") == 0 || args.indexOf("https://") == 0) {
          return true;
      } else if (args || args === undefined || args === null || args === "") {
          return false;
      } else {
          return true;
      }
    },
    openNCloseFilter() {
      let filter = this.$refs.filterList;
      if (filter.classList.contains("d-block")) {
        filter.classList.remove("d-block");
      } else {
        filter.classList.add("d-block");
      }
    },
    loadMore() {
      this.paginationList += 9;
    },
    showBannerModal(val) {
      this.$refs["bannerModal"].show();
      const videoSrc = val;
      var videoSrcNew = videoSrc + "/?autoplay=1&rel=0&mute=1";
      this.bannerUrl = videoSrcNew;
    },
    sanitizeSearch() {
      this.filter.search = this.filter.search.replace(/[^a-zA-Z0-9 ]/g, "");
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
  }
};
