
import { Glide, GlideSlide } from "vue-glide-js";
import { testimonialCarousel } from "~/assets/sliderConfig/testimonialCarousel";
import { globalPartnersCarousel } from "~/assets/sliderConfig/globalPartnersCarousel";
export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      testimonialCarousel: testimonialCarousel(),
      globalPartnersCarousel: globalPartnersCarousel()
    };
  },
  mounted() {}
};
