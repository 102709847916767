
import { Glide, GlideSlide } from "vue-glide-js";
import { caseStudyCarousel } from "~/assets/sliderConfig/section42";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      caseStudyCarousel: caseStudyCarousel(),
      currentCaseStudyCarousel: 0
    };
  },
  methods: {
    moveSlide(index) {
      this.currentCaseStudyCarousel = index;
      this.$refs.caseStudyPrimary.glide.go(`=${index}`);
    }
  }
};
