export const section59 = () => {
  return {
    perView: 3,
    type: "slider",
    bullet: false,
    gap: 10,
    dragThreshold: false,
    animationTimingFunc: "ease-in-out",
    breakpoints: {
      992: {
        perView: 3.1,
        focusAt: "center",
        dragThreshold: false
      },
      620: {
        perView: 1.1,
        focusAt: "center",
        dragThreshold: false,
        peek: 50
      }
    }
  };
};
