
import { solutionsCardCarousel } from "~/assets/sliderConfig/solutionsCardCarousel";
import { Glide, GlideSlide } from "vue-glide-js";
export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      resourceCarousel: solutionsCardCarousel()
    };
  },
  mounted() {}
};
