
import { mapState } from "vuex";

export default {
  components: {
    tataKBHeader: () => import("~/components/tataKBHeader"),
    TataFooterFaqs: ()=> import('~/components/tataFooterFaqs.vue'),
    scrollToTopSection : () =>import("~/components/global-components/tata-scroll-to-top-cta")
  },
  scrollToTop: true,
  data: function () {
    return {
      uniqueKey: 0
    };
  },
  computed: {
    ...mapState("l1", ["itemSpecificData"]),
    show400: function () {
      return this.$route.query.status
        ? this.$route.query.status.indexOf("4") === 0
        : true;
    }
  },
  watch: {
    $route: function (to, from) {
      if (to.path !== from.path) this.uniqueKey++; // Adding unique key to nuxt on route change for transitions
    }
  }
};
