
export default {
  data() {
    return {
      is_user_clicked: false
    };
  },
  mounted() {
    let _vm = this;
    document.addEventListener("scroll", function () {
      let slideInPopup = document.querySelector(".slideInpopup");
      if (slideInPopup) {
        if (
          Math.round(_vm.getVerticalScrollPercentage(document.body)) >=
            _vm.$attrs.scrolling_length &&
          !_vm.is_user_clicked
        ) {
          slideInPopup.classList.add("slideShow");
        } else if (_vm.is_user_clicked) {
          slideInPopup.classList.remove("slideShow");
        }
      }
    });
  },
  methods: {
    getVerticalScrollPercentage(elm) {
      var p = elm.parentNode;
      return (
        ((elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight)) *
        100
      );
    },
    closePopup() {
      this.is_user_clicked = true;
      let slideInPopup = document.querySelector(".slideInpopup");
      slideInPopup.classList.remove("slideShow");
      slideInPopup.classList.add("closed");
    }
  }
};
