
import { Glide, GlideSlide } from "vue-glide-js";
import { section25 } from "~/assets/sliderConfig/section25";
export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      section25: section25()
    };
  }
};
