
  import { Glide, GlideSlide } from "vue-glide-js";
  import { resourceFeaturedSlider } from "~/assets/sliderConfig/resourceFeaturedSlider";
  
  export default {
    components: {
      [Glide.name]: Glide,
      [GlideSlide.name]: GlideSlide
    },
    data: () => {
      return {
        resourceFeaturedSlider: resourceFeaturedSlider(),
        slider_data: [],
        analystData:null,
        bannerSlider: {
          items: [],
          isAnalystRecognition: true,
        },
      };
    },
    mounted() {
      this.updateSliderData();
    },
    async fetch() {
      let { store } = this.$nuxt.context;
      let awards = await store.dispatch("analyst-recognition/getAllAwards");
      awards?.featured_analyst_recognistion?.forEach((ele) => {
        let image = {
          src: "https://gamma.tatacommunications.com/assets/wp-content/uploads/2021/12/Awards-Image.jpeg"
        };
        if (ele.images) {
          image.src = ele.images[0].image.url;
        }

        this.bannerSlider.items.push({
          title: ele.title,
          sub_title: ele.subtitle,
          featured_image: image,
          slug: ele.cta ? ele.cta.url : "",
          slug_title: ele.cta ? ele.cta.title : "",
          has_featured_image: true
        });
      });
      this.updateSliderData();
    },
    methods: {
      updateSliderData() {
        if (!this.bannerSlider || !this.bannerSlider.items) {
          return;
        }

        this.slider_data = [];
        this.analystData = this.bannerSlider.isAnalystRecognition;
        this.bannerSlider.items.forEach(item => {
          let featured_image = item.has_featured_image ? item.featured_image : null;
          this.slider_data.push({
            title: item.title,
            sub_title: item.sub_title ? item.sub_title : (this.analystData ? "Featured Analyst Recognition" : "Featured Award"),
            description: item.excerpt,
            featured_image: featured_image,
            slug: item.slug,
            slug_title: item.slug_title,
          });
        });
      }
    }
  };
