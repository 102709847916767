
export default {
  props: {
    indexNum: {
      type: Number,
      default: 1
    },
    cardData: {
      type: Object,
      default: () => {
        return {
          feature_image: {
            url: "https://placehold.it/200"
          },
          title: "test"
        };
      },
      // required: true,
      validator: function (value) {
        return value && Object.keys(value).length;
      }
    },
    hasDefaultImage: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    unlockCard() {
      return this.$store.getters["resource/resourceUnlocked"];
    }
  },
  methods: {
    getFeatureImage() {
      if (this.cardData.feature_image) {
        return this.cardData.feature_image;
      }
      if (this.cardData.featured_image) {
        return this.cardData.featured_image;
      }
      return false;
    }
  }
};
