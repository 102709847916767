export const section73 = () => {
  return {
    perView: 1,
    type: "carousel",
    bullet: false,
    gap: 10,
    animationTimingFunc: "ease-in-out",
    breakpoints: {
      992: {
        bullet: true
      }
    }
  };
};
