
import Vue from "vue";
export default {
  props: {
    postType: {
      type: String,
      default: ""
    }
  },

  data: function () {
    return {
      sectionArray: [],
      breadCrumb: null,
      cssText: "",
      isPopupForm: false,
      formData: false,
      showLoader: true
    };
  },
  async fetch() {
    try {
      const { params, store, route } = this.$nuxt.context;
      if (route.name === "index") {
        params.l1 = "home-page";
      }

      const response = await store.dispatch("l1/getL1TemplateData", params);
      const res = response.data;
      if (response && res) {
        this.showLoader = false;
        this.breadCrumb = res.breadscrum;
        this.sectionArray = res.section_list;
        this.cssText = res.item_specific_data.custom_css;
        this.isPopupForm = res.pop_up_form.enable_popup;
        if (this.isPopupForm) {
          this.formData = res.pop_up_form.popup_section;

          if (this.formData) {
            this.formData.eventData = {
              event: "TataFormSubmit",
              category: "Form submit",
              action: "Popup",
              label: "https://www.tatacommunications.com" + this.$route.fullPath
            };
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: "en"
      },
      title: this.$store.getters["meta/metaTitle"],
      meta: this.$store.getters["meta/meta"],
      link: [
        {
          rel: "canonical",
          href: this.$store.getters["meta/canonicalUrl"]
            ? this.$store.getters["meta/canonicalUrl"]
            : "https://www.tatacommunications.com" + this.$route.fullPath
        }
      ],
      style: [
        { cssText: this.cssText.replace(/&quot;/gi, '"'), type: "text/css" }
      ]
    };
  },
  computed: {},
  beforeCreate() {
    this.showLoader = true;
  },
  mounted() {},
  beforeMount() {
    //window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
