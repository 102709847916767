
import Vue from "vue";
import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);

export default {
  props: ["sectionData"],
  data() {
    return {
      poppedOut: false,
      showVideo: false, // New data property
      playerVars: {
        controls: 0,
        rel: 0,
        showinfo: 0,
        wmode: "transparent"
      }
    };
  },
  computed: {
    videoId() {
      return this.$youtube.getIdFromUrl(this.sectionData.video_link);
    }
  },
  methods: {
    popOutVideo() {
      this.poppedOut = !this.poppedOut;
    },
    playVideo() {
      this.showVideo = !this.showVideo;
    }
  }
};
