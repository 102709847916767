export const section77 = () => {
  return {
    perView: 3,
    gap: 30,
    // focusAt: "center",
    type: "slider",
    autoplay: 5000,
    bullet: false,
    hoverpause: true,
    rewind: true,
    breakpoints: {
      992: {
        perView: 2
      },
      600: {
        perView: 1,
        gap: 30
      }
    }
  };
};
