
export default {
  props: {
    indexNum: {
      type: Number,
      default: 1
    }
  },
  data: () => {
    return {
      active: false,
      activeIndex: -1,
      arrItems: [1, 2, 3, 4, 5, 6],
      defaultItem: 4,
      expandCard: false,
      activeIndexNum: -1,
      visible: true,
      flag: false
    };
  },
  computed: {
    cardFirstFourItems: function () {
      let arr = [];
      if (this.arrItems.length > 4) {
        arr = this.arrItems.slice(0, 4);
      } else {
        arr = this.arrItems;
      }
      return arr;
    },
    cardItems: function () {
      let arr = [];
      if (this.arrItems.length > 4) {
        arr = this.arrItems.slice(3, 6);
      } else {
        arr = this.arrItems;
      }
      return arr;
    }
  },
  watch: {},
  methods: {
    close() {
      this.visible = false;
      this.$store.commit("UpdateExpandPrimaryCard", false);
      this.$store.commit("UpdateActiveCardIndex", -1);
    }
  }
};
