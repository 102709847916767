
export default {
  data() {
    return {
      ampStoryConfig: {
        behavior: {
          pageScroll: true,
          autoplay: true
        },
        controls: [
          {
            name: "close",
            position: "start"
          },
          {
            name: "skip-to-next"
          }
        ]
      }
    };
  }
};
