
import tataBlueCta from "../tata-blue-cta.vue";
export default {
  components: { tataBlueCta },
  data() {
    return {
      start: 5,
      limit: 5
    };
  },
  mounted() {
    if (this.$attrs.faq_schema) {
      this.$nuxt.context.app.head.script.push({
        hid: "faq_schema",
        type: "application/ld+json",
        json: JSON.parse(this.$attrs.faq_schema)
      });
    }
  }
};
