export const newhomebanner = () => {
    return {
        autoplay: 7000,
        perView: 1,
        type: "carousel",
        dragThreshold: true,
        hoverpause: true,
        bullet: true,
        gap: 30,
        breakpoints: {
            992: {
                perView: 1,
                gap: 10,
                type: "carousel"
            }
        }
    };
};
