
export default {
  data() {
    return {
      iframe: false
    };
  },
  mounted() {},
  methods: {
    showModal(val) {
      this.$refs["map_popup"].show();
      this.iframe = val;
    }
  }
};
