export const section21 = () => {
  return {
    perView: 3.2,
    type: "carousel",
    bullet: false,
    gap: 30,
    animationTimingFunc: "ease-in-out",
    breakpoints: {
      1140: {
        perView: 2.2,
        gap: 30
      },
      600: {
        perView: 1.2,
        gap: 30
      }
    }
  };
};
