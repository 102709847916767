export const state = () => ({
  newsCenterList: [],
  newsCenterData: null
});
export const getters = {
  newCenterList(state) {
    return state.newCenterList;
  },
  newsCenterData(state) {
    return state.newsCenterData;
  }
};
export const mutations = {
  updateNewCenterList(state, data) {
    state.newCenterList = data;
  },
  updateNewCenterData(state, data) {
    state.newsCenterData = data;
  }
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async getNewsCenter({ dispatch, commit }, params) {
    try {
      let res = await this.$axios.get(`news-center?type=${params}`, {
        useCache: true
      });
      commit("updateNewCenterList", res);
      return res;
    } catch (err) {
      console.error(err);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getNewsCenterDetails({ dispatch, commit }, content) {
    try {
      let res = await this.$axios.get(
        `news-center-details?slug=${content.params.slug}&post_type=${content.parent}`
      );
      commit("updateNewCenterData", res);
      commit("l1/updatePageTitle", res.data.title, { root: true });
      return res;
    } catch (err) {
      console.error(err);
    }
  }
};
