
    import { Glide, GlideSlide } from "vue-glide-js";
    import { moveCardCarousel } from "~/assets/sliderConfig/moveCardCarousel";
    export default {
        components: {
            [Glide.name]: Glide,
            [GlideSlide.name]: GlideSlide
        },
        data: () => {
            return {
                moveCarousel: moveCardCarousel()
            };
        },  
    }
    