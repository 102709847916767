
  import { Glide, GlideSlide } from "vue-glide-js";
  import gsap from "gsap";
  import ScrollTrigger from "gsap/dist/ScrollTrigger";
  export default {
    components: {
      [Glide.name]: Glide,
      [GlideSlide.name]: GlideSlide
    },
    data() {
      return {
        options: {
          type: "carousel",
          perView: 2,
          focusAt: "center",
          gap: 30
        },
        tabIndex: 0,
        timeline: undefined,
        scrollTrigger: undefined,
        isMobile: false,
        activeItem: 0,
        isReady: true,
      };
    },
    mounted() {
      if (window.innerWidth < 650) {
        this.isMobile = true;
      }

      $(function () {
        $('.btm_slider_desk').slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            variableWidth: false,
            infinite: false,
            lazyLoad: 'ondemand'
        });
      });

      // $('.nav.nav-tabs li a').on('click', () => {
      //   console.log('clicked');
      // // $('.btm_slider_desk').slick('refresh')

      //  });

      // console.log(this.caseStudyCarousel);

     
    },
    methods: {
      

      handleTabClick(value) {
        // alert('Button clicked');
        // if (!this.isMobile) {
        //   let topValue = document.querySelector(
        //     ".tata__card--scrollTab"
        //   ).offsetTop;
        //   if (value == 0) {
        //     // console.log(0, 500);
        //     window.scrollTo(0, topValue + 200);
        //   } else if (value == 1) {
        //     // console.log(0, 1000);
        //     window.scrollTo(0, topValue + 700);
        //   } else if (value == 2) {
        //     // console.log(0, 1500);
        //     window.scrollTo(0, topValue + 1300);
        //   } else if (value == 3) {
        //     // console.log(0, 2000);
        //     window.scrollTo(0, topValue + 1700);
        //   }
        //   this.scrollTrigger.refresh();
        // }
        $('.btm_slider_desk').slick('refresh');
      },
      animateOnScroll() {
        // gsap.registerPlugin(ScrollTrigger);
  
        // this.scrollTrigger = ScrollTrigger;
  
        let tabLinks = document.querySelectorAll(".nav-item");
        let tabContents = document.querySelectorAll(".tab-pane");
  
        function activeTabSwiwtcher(value) {
          tabLinks.forEach((tabLink) => {
            tabLink
              .getElementsByTagName("a")[0]
              .setAttribute("aria-selected", "false");
            tabLink.getElementsByTagName("a")[0].classList.remove("active");
          });
          tabLinks[value]
            .getElementsByTagName("a")[0]
            .setAttribute("aria-selected", "false");
          tabLinks[value].getElementsByTagName("a")[0].classList.add("active");
  
          tabContents.forEach((tabContent) => {
            tabContent.setAttribute("aria-hidden", "true");
            tabContent.classList.remove("active");
            tabContent.style.display = "none";
          });
          tabContents[value].setAttribute("aria-hidden", "false");
          tabContents[value].classList.add("active");
          tabContents[value].style.display = "block";

          // const slider = document.querySelector(".btm_slider_desk");
          //   this.$nextTick(() => {
          //               setTimeout(function() {
          //                 const slider = document.querySelector(".btm_slider_desk");
          //                 if (slider && typeof slider.slick === "function") {
          //                   slider.slick({ // Initialize the slider with the new settings
          //                     slidesToShow: 2,
          //                   slidesToScroll: 1,
          //                   arrows: true,
          //                   variableWidth: false,
          //                   infinite: false
          //                   });
          //                 }
          //               }, 500);
          //   });

          // setTimeout(() => {
          //     const slider = this.$refs.slider;
          //     console.log(slider);
          //     if (slider && typeof slider.slick === "function") {
          //       const prevButton = $('.slick-prev');

          //       prevButton.trigger('click');

          //       console.log('Initializing Slick slider');
          //       $('.btm_slider_desk').slick('refresh');
          //     }

              
          //   }, 500);
        }
  
          // if (document.querySelector(".pin-spacer")) {
          //   let pinSpacer = window.getComputedStyle(document.querySelector(".pin-spacer")).getPropertyValue("height");
          //   console.log(pinSpacer, "dsad");
          //   document.querySelector(".pin-spacer").setAttribute("data-heightMax", pinSpacer);
          // }
  
        // this.timeline = gsap.timeline({
        //   id: "myTimeline",
        //   scrollTrigger: {
        //     trigger: ".pn_container",
        //     pin: true,
        //     start: "50% 50%",
        //     end: "2000",
        //     scrub: true,
        //     anticipatePin: 1,

        //     // markers: true,
        //     onUpdate({ progress }) {
        //       progress = progress * 100;
        //       if (progress < 25) {
        //         activeTabSwiwtcher(0);
        //       } else if (progress < 50) {
        //         activeTabSwiwtcher(1);
        //       } else if (progress < 75) {
        //         activeTabSwiwtcher(2);
        //       } else if (progress < 90) {
        //         activeTabSwiwtcher(3);
        //       }
        //     }
        //   }
        // });
  
        // this.timeline.to(".tabContent", { x: 0, duration: 40 });
  
        // this.timeline.scrollTrigger.refresh();
      },
      enableMobile() {
        // console.log("mobile device");
      },
      handleMobileAccordion(id) {
        if (id != this.activeItem) {
          this.activeItem = id;
        } else {
          this.activeItem = null;
        }
      },
      reInitSlider() {
        this.isReady = false;
        // console.log(this.isReady);
        this.$nextTick(() => {
          this.isReady = true;
        });
      }
    },
    head:{
      script: [
      {
        src: "https://code.jquery.com/jquery-3.5.1.min.js",
        body: true,
      },
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js",
        body: true,

      }
      ],
      link: [
        { rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css' }
      ]
    }
  };
  