
import { Glide, GlideSlide } from "vue-glide-js";
import { resourceCenterCarousel } from "~/assets/sliderConfig/resourceCenterCarousel";
import { mapGetters } from "vuex";
import Cookies from "js-cookie";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  data: () => {
    return {
      resourceCenterCarousel: resourceCenterCarousel(),
      resourceData: null,
      resourceRightData: { items: [] },
      popupData: false
    };
  },
  async fetch() {
    try {
      const { params, store } = this.$nuxt.context;

      if (params.tag_slug) {
        // Get tags
        await store.dispatch("resource/getResourceTagData", params);
      } else {
        // Get categories
        let slugObject = params.parent_cat_slug
          ? params
          : {
              parent_cat_slug: "all_child_cats_resources"
            };
        let slug = {};
        if (!params.parent_cat_slug && !params.child_cat_slug) {
          slug.cat_slug = "all";
        }
        if (params.parent_cat_slug && !params.child_cat_slug) {
          slug.cat_slug = params.parent_cat_slug;
        }
        if (params.parent_cat_slug && params.child_cat_slug) {
          slug.cat_slug = params.child_cat_slug;
        }
        let slugs = { params: slugObject, cat_slug: slug };
        store.dispatch("resource/getAllResourcesData", slug);
        await store.dispatch("resource/getResourceRightData", slugs);
      }

      // await store.dispatch("l1/getL1TemplateData", params);
    } catch (error) {
      console.error(error);
    }
  },
  // async fetch() {
  //
  // },
  computed: {
    ...mapGetters("resource", ["dataToShow", "showSearch", "breadcrumbs"])
  },
  mounted() {
    this.$store.commit("resource/updateShowSearch", false);
    let tcRcCookie = "";
    if (process.client) {
      tcRcCookie = Cookies.get("tc_rc_form");
    } else {
      let strCookie = new RegExp("tc_rc_form[^;]+").exec(
        this.$nuxt.context.req.headers.cookie
      );
      // eslint-disable-next-line no-unused-vars
      tcRcCookie = unescape(
        strCookie ? strCookie[0].toString().replace(/^[^=]+./, "") : ""
      );
    }
    this.$store.commit("resource/updateResourceUnlock", tcRcCookie);
    window.scrollTo({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line no-empty
    if (window.screen.width < 768) {
    } else {
      for (let glide in this.$refs.glideSlider) {
        this.$refs.glideSlider[glide].glide.disable();
      }
    }
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    opneWebinar(channelId, webinarId) {
      this.$refs["webinar_popup"].show();
      this.popupData = { channel_id: channelId, webinar_id: webinarId };
    },
    showPopupData() {
      let brightTalkDiv = document.querySelector(
        ".webinar_popup .jsBrightTALKEmbedWrapper"
      );
      let channel_id = this.popupData.channel_id,
        webinar_id = this.popupData.webinar_id;
      let ScriptTag = document.createElement("script");
      ScriptTag.type = "application/json";
      ScriptTag.className = "jsBrightTALKEmbedConfig";
      let scriptText = document.createTextNode(
        '{ "channelId": ' +
          channel_id +
          ' , "language": "en-US", "commId": ' +
          webinar_id +
          ', "displayMode": "standalone", "height": "auto"}'
      );
      ScriptTag.appendChild(scriptText);

      brightTalkDiv.appendChild(ScriptTag);
      let ScriptTag2 = document.createElement("script");
      ScriptTag2.src =
        "https://www.brighttalk.com/clients/js/player-embed/player-embed.js";
      ScriptTag2.className = "jsBrightTALKEmbed";
      brightTalkDiv.appendChild(ScriptTag2);
    }
  }
};
