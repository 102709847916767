
export default {
  data: () => {
    return {
      showTickerBanner: true
    };
  },
  methods: {
    toggleTickerBannerVisibility() {
      this.showTickerBanner = !this.showTickerBanner; // Toggle the visibility of the ticker-banner section
    }
  }
};
