
export default {
  data() {
    return {
      form: null,
      subscribe: null,
      subbutton: null,
      sharebutton: null,
      button1: null,
      button2: null,
      changeshareIcon: null,
      overlay: null,
      formclicked: false,
      subscibeclicked: false,
      timer: null
    };
  },
  mounted() {
    this.form = document.getElementsByClassName("form-content")[0];
    this.subscribe = document.getElementsByClassName("subscribe-content")[0];
    this.subbutton = document.getElementsByClassName("subscribeButton")[0];
    this.sharebutton = document.getElementsByClassName("shareButton")[0];
    this.button1 = document.getElementsByClassName("button1")[0];
    this.button2 = document.getElementsByClassName("button2")[0];
    this.changeshareIcon = document.getElementById("share");
    this.overlay = document.getElementsByClassName("newOverlay")[0];
  },
  methods: {
    formButtonHandler() {
      if (this.subscibeclicked) {
        this.subscribe.style.right = "-350px";
        this.button1.style.right = "0";
        this.button2.style.right = "0";
        this.sharebutton.style.background =
          "linear-gradient(180deg, #165E9E 0%, #0F3F6A 97.52%), #0618CF";
        this.sharebutton.style.color = "white";
        this.changeshareIcon.src =
          "https://beta.tatacommunications.com/wp-content/uploads/2023/05/share-float-1.png";
        this.timer = setTimeout(() => {
          this.formButtonStyleHandler();
          this.subscibeclicked = false;
        }, 500);
      } else {
        this.formButtonStyleHandler();
      }
    },
    subsribeButtonHandler() {
      if (this.formclicked) {
        this.form.style.right = "-350px";
        this.button1.style.right = "0";
        this.button2.style.right = "0";
        this.subbutton.style.background =
          "linear-gradient(180deg, #165E9E 0%, #0F3F6A 97.52%), #0618CF";
        this.subbutton.style.color = "white";
        this.subbutton.querySelector("#sub").src =
          "https://beta.tatacommunications.com/wp-content/uploads/2023/05/white-mail-icon.png";
        setTimeout(() => {
          this.subscribeButtonStyleAdder();
          this.formclicked = false;
        }, 500);
      } else {
        this.subscribeButtonStyleAdder();
      }
    },

    subscribeButtonStyleAdder() {
      if (
        this.subscribe.style.right === "" ||
        this.subscribe.style.right === "-350px"
      ) {
        this.subscribe.style.right = "0";
        this.button2.style.right = "350px";
        this.subscibeclicked = true;
      } else {
        this.overlay.style.display = "none";
        this.subscibeclicked = false;
        this.subscribe.style.right = "-350px";
        this.button2.style.right = "0px";
        this.subscibeclicked = false;
      }

      if (
        this.button2.style.right === "350px" ||
        this.button1.style.right === "0" ||
        this.subscribe.style.right === "0"
      ) {
        this.sharebutton.style.background = "white";
        this.changeshareIcon.src =
          "https://beta.tatacommunications.com/wp-content/uploads/2023/05/share-blue.png";
        this.sharebutton.style.color = "#002138";
        this.overlay.style.display = "block";
      } else {
        this.sharebutton.style.background =
          "linear-gradient(180deg, #165E9E 0%, #0F3F6A 97.52%), #0618CF";
        this.changeshareIcon.src =
          "https://beta.tatacommunications.com/wp-content/uploads/2023/05/share-float-1.png";
        this.sharebutton.style.color = "#fff";
        this.overlay.style.display = "none";
      }
      clearTimeout(this.timer);
    },

    formButtonStyleHandler() {
      if (this.form.style.right === "" || this.form.style.right === "-350px") {
        this.form.style.right = "0";
        this.button1.style.right = "350px";
        this.button2.style.right = "350px";
        this.formclicked = true;
      } else {
        this.form.style.right = "-350px";
        this.button1.style.right = "0";
        this.button2.style.right = "0";
        this.formClicked = false;
      }
      if (
        this.button1.style.right === "350px" ||
        this.form.style.right === "0"
      ) {
        this.subbutton.style.background = "white";
        this.subbutton.querySelector("#sub").src =
          "https://beta.tatacommunications.com/wp-content/uploads/2023/05/subscribe-float.png";
        this.subbutton.style.color = "#002138";
        this.overlay.style.display = "block";
      } else {
        this.subbutton.style.background =
          "linear-gradient(180deg, #165E9E 0%, #0F3F6A 97.52%), #0618CF";
        this.subbutton.querySelector("#sub").src =
          "https://beta.tatacommunications.com/wp-content/uploads/2023/05/white-mail-icon.png";
        this.subbutton.style.color = "#fff";
        this.overlay.style.display = "none";
      }
      clearTimeout(this.timer);
    },

    off() {
      if (this.form.style.right === "0" || this.subscribe.style.right === "0") {
        this.overlay.style.display = "block";
      } else if (this.formclicked || this.subscibeclicked) {
        this.overlay.style.display = "none";
        this.form.style.right = "-350px";
        this.subscribe.style.right = "-350px";
        this.button1.style.right = "0";
        this.button2.style.right = "0";
        this.sharebutton.style.background =
          "linear-gradient(180deg, #165E9E 0%, #0F3F6A 97.52%), #0618CF";
        this.changeshareIcon.src =
          "https://beta.tatacommunications.com/wp-content/uploads/2023/05/share-float-1.png";
        this.sharebutton.style.color = "#fff";
        this.subbutton.style.background =
          "linear-gradient(180deg, #165E9E 0%, #0F3F6A 97.52%), #0618CF";
        this.subbutton.querySelector("#sub").src =
          "https://beta.tatacommunications.com/wp-content/uploads/2023/05/white-mail-icon.png";
        this.subbutton.style.color = "#fff";
      }
    },

    scrolltoId() {
      var access = document.getElementById("section2");
      access.scrollIntoView();
    },

    scrolltofooter() {
      var element = document.getElementById("subscribeForm");
      var headerOffset = 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }
};
