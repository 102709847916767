import Vue from "vue";

Vue.mixin({
  methods: {
    filterUrl(arg) {
      let allDomains = [
        "tatacommunications.com",
        "www.tatacommunications.com",
        "development.tatacommunications.com"
      ];
      try {
        let url = new URL(arg);
        let host = url.host;
        if (allDomains.indexOf(host) > -1) {
          return url.pathname;
        } else {
          return arg;
        }
      } catch (e) {
        return arg;
      }
    },
    isUrl(arg) {
      try {
        // eslint-disable-next-line no-unused-vars
        let url = new URL(arg);
        return true;
      } catch (e) {
        return false;
      }
    }
  }
});
