
import { solutionsCardCarousel } from "~/assets/sliderConfig/solutionsCardCarousel";
import { Glide, GlideSlide } from "vue-glide-js";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  props: {
    hasContainer: {
      type: Boolean,
      require: false,
      default: false
    },
    isPressRelease : {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data: () => {
    return {
      resourceCarousel: solutionsCardCarousel()
    };
  }
};
