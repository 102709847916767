
export default {
  props: {
    activeTabIndex: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      activeTab: -1,
    };
  },
  methods: {
    scrollToSection(sectionId, tabIndex) {
      this.activeTab = tabIndex;

      const targetElement = document.querySelector(`#${sectionId}`);
      if (targetElement) {
        const offset = 320; // Adjust the offset as needed
        const scrollToPosition = targetElement.offsetTop - offset;

        // Scroll to the top of the section with an offset
        window.scrollTo({
          top: scrollToPosition,
          behavior: 'smooth',
        });
      }
    },
    scrollToHash() {
      this.$nextTick(() => {
        const hash = window.location.hash;
        if (hash) {
          const targetElement = document.querySelector(hash);
          if (targetElement) {
            const offset = 320; // Adjust the offset as needed
            const scrollToPosition = targetElement.offsetTop - offset;

            // Scroll to the top of the section with an offset
            window.scrollTo({
              top: scrollToPosition,
              behavior: 'smooth',
            });
          }
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToHash();
    });
  },
  watch: {
    $route() {
      this.scrollToHash();
    }
  }
};
