
export default {
  props: {
    eventCode: {
      type: String,
      default: ""
    }
  },
  data: () => {
    return {
      openVideo: false
    };
  },
  mounted() {},
  methods: {
    close() {
      this.openVideo = false;
      const el = document.getElementById("strategicOverviewVideo");
      const elSrc = el.src;
      el.src = elSrc;
    }
  }
};
