export default function (to, from) {
  if (to.hash) {
    if (to.hash !== "#" && document.querySelector(to.hash)) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop + window.innerHeight,
        behavior: "smooth"
      });
    } else {
      return false;
    }
  }
  if (to.path !== from.path) {
    return window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
