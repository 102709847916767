
export default {
  data: () => {
    return {
      tabData: [
        {
          heading: "test 1",
          title: "test titile 1",
          desc: "test desc 1",
          background: "https://placehold.it/300"
        },
        {
          heading: "test 2",
          title: "test titile 2",
          desc: "test desc 2",
          background: "https://placehold.it/400"
        },
        {
          heading: "test 3",
          title: "test titile 3",
          desc: "test desc 3",
          background: "https://placehold.it/500"
        }
      ],
      activeTabIndex: 0
    };
  },
  computed: {},
  methods: {
    changeActiveTab(activeIndex) {
      this.activeTabIndex = activeIndex;
    }
  }
};
