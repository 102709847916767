
import Vue from "vue";

export default {
  layout: "error",
  // eslint-disable-next-line vue/require-prop-types
  props: ["error"],
  head() {
    return {
      htmlAttrs: {
        lang: "en"
      },
      title: this.$store.getters["meta/metaTitle"],
      meta: this.$store.getters["meta/meta"],
      link: [
        {
          rel: "canonical",
          href: this.$store.getters["meta/canonicalUrl"]
            ? this.$store.getters["meta/canonicalUrl"]
            : "https://www.tatacommunications.com" + this.$route.fullPath
        }
      ]
    };
  },
  computed: {
    show400: function () {
      if (this.error.statusCode === 404) {
        Vue.component("Error404", () =>
          import("~/components/global-components/error404.vue")
        );
        return "error404";
      }
      Vue.component("Error500", () =>
        import("~/components/global-components/error500.vue")
      );
      return "error500";
    }
  },
  mounted() {
    this.$store.dispatch("l1/updatePageTitle", "Error 404: Page not found");
    let searchMeta = {
      metaData: {
        fb_title: `Page not found | Tata Communications`,
        fb_image: "~/assets/img/Tata-Communications-App-Icon.png",
        fb_image_width: 450,
        fb_image_height: 450,
        twitter_title: `Page not found | Tata Communications`,
        seo_meta_title: `Page not found | Tata Communications`,
        robots: "noindex,follow"
      }
    };
    this.$store.dispatch("meta/updateMeta", searchMeta);
  },
  render: function (h) {
    // eslint-disable-next-line no-undef
    return createDynamicLayout(h);
  }
};
