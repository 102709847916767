import Vue from "vue";
// import Modernizr from "modernizr";

Vue.directive("webp-src", {
  inserted: (el, binding) => {
    initLoadImage(el, binding);
  },
  update: (el, binding) => {
    initLoadImage(el, binding);
  }
});

function initLoadImage(img, binding) {
  if (binding.value === "slider") {
    let images = img.querySelectorAll("img");
    images.forEach((image) => {
      if (image.dataset.src) {
        image.src =
          "data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
      }
      loadImages(image);
    });
  } else if (binding.value === "background") {
    loadBackgroundImage(img);
  } else {
    if (img.classList.contains("loaded")) {
      return false;
    }
    if (img.dataset.src) {
      img.src = "data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
    }
    loadImages(img);
  }
}

function loadImages(el) {
  function loadImage() {
    el.addEventListener("load", function () {
      setTimeout(() => el.classList.add("loaded"), 100);
    });
    el.addEventListener("error", () => {
      console.error("image error");
    });
    //
    if (!el.dataset.src) return;

    if (el.dataset.src.indexOf("gamma.tatacommunications.com") === -1) {
      el.src = el.dataset.src;
      return;
    }
    let dataSrcset = el.dataset.srcset;
    let regExp = new RegExp(
      // eslint-disable-next-line no-control-regex
      "(http(s?):)([/|.|\\w|\\s|-])*\\.(?:jpg|jpeg|png)",
      "g"
    );
    if (dataSrcset) {
      dataSrcset = dataSrcset.replace(/(\?.*)|(#.*)/g, "");
      if (dataSrcset.match(regExp)) {
        dataSrcset = dataSrcset.replace(regExp, "$&.webp");
      }
    }
    let dataSrc = el.dataset.src.replace(/(\?.*)|(#.*)/g, "");
    let dataSrcWebp = dataSrc;

    //if jpeg/png
    if (dataSrc.match(regExp)) {
      dataSrcWebp = dataSrc.replace(regExp, "$&.webp");
    }
    // eslint-disable-next-line no-undef
    Modernizr.on("webp", function (result) {
      if (result) {
        el.src = dataSrcWebp;
        if (dataSrcset) {
          el.srcset = dataSrcset;
        }
      }
    });
  }

  function handleInspector(entities, observer) {
    entities.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      } else {
        loadImage();
        observer.unobserve(el);
      }
    });
    // entities.in
  }

  function createObserver() {
    let options = {
      root: null,
      rootMargin: "100px 100px",
      threshold: 0.5
    };
    const observer = new IntersectionObserver(handleInspector, options);
    observer.observe(el);
  }

  if (!window["IntersectionObserver"]) {
    loadImage();
  } else {
    createObserver();
  }
}

function loadBackgroundImage(el) {
  function loadImage() {
    el.addEventListener("load", function () {
      setTimeout(() => el.classList.add("loaded"), 100);
    });
    el.addEventListener("error", () => {
      console.error("image error");
    });
    //
    if (!el.dataset.src) return;

    if (el.dataset.src.indexOf("gamma.tatacommunications.com") === -1) {
      el.style.backgroundImage = `url(${el.dataset.src}`;
      return;
    }

    let regExp = new RegExp(
      // eslint-disable-next-line no-control-regex
      "(http(s?):)([/|.|\\w|\\s|-])*\\.(?:jpg|jpeg|png)",
      "g"
    );
    let dataSrc = el.dataset.src.replace(/(\?.*)|(#.*)/g, "");
    let dataSrcWebp = dataSrc;

    //if jpeg/png
    if (dataSrc.match(regExp)) {
      dataSrcWebp = dataSrc.replace(regExp, "$&.webp");
    }
    // eslint-disable-next-line no-undef
    Modernizr.on("webp", function (result) {
      if (result) {
        el.style.backgroundImage = `url(${dataSrcWebp}`;
      }
    });
  }
  function handleInspector(entities, observer) {
    entities.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      } else {
        loadImage();
        observer.unobserve(el);
      }
    });
    // entities.in
  }

  function createObserver() {
    let options = {
      root: null,
      rootMargin: "100px 100px",
      threshold: 0.5
    };
    const observer = new IntersectionObserver(handleInspector, options);
    observer.observe(el);
  }

  if (!window["IntersectionObserver"]) {
    loadImage();
  } else {
    createObserver();
  }
}

Vue.directive("table-of-content", {
  // eslint-disable-next-line no-unused-vars
  inserted: (el, binding) => {
    //table of content
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const id = entry.target.getAttribute("id");

        if (entry.intersectionRatio > 0) {
          el.querySelector(
            `.tabs-sidebar__nav li a[href="#${id}"]`
          ).classList.add("active");
        } else {
          el.querySelector(
            `.tabs-sidebar__nav li a[href="#${id}"]`
          ).classList.remove("active");
        }
      });
    });

    // Track all sections that have an `id` applied
    el.querySelectorAll(`h2[id]`).forEach((section) => {
      observer.observe(section);
    });
  }
});

//click Out side


Vue.directive('click-outside', {
  bind: function (element, binding, vnode) {
    element.clickOutsideEvent = function (event) {  //  check that click was outside the el and his children
      if (!(element === event.target || element.contains(event.target))) { // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
        // binding.value(); run the arg
      }
    };
    document.body.addEventListener('click', element.clickOutsideEvent)
  },
  unbind: function (element) {
    document.body.removeEventListener('click', element.clickOutsideEvent)
  }
})

