export const section39 = () => {
  return {
    perView: 4,
    type: "carousel",
    bullet: false,
    dragThreshold: false,
    gap: 20,
    breakpoints: {
      992: {
        perView: 3,
        type: "carousel",
        focusAt: "center"
      },
      620: {
        perView: 1.3,
        focusAt: "center"
      }
    }
  };
};
