export const section19 = () => {
  return {
    perView: 3,
    gap: 20,
    focusAt: "center",
    type: "carousel",
    bullet: false,
    breakpoints: {
      600: {
        perView: 1.2,
        gap: 30
      }
    }
  };
};
