export const globalPartnersCarousel = () => {
  return {
    focusAt: "center",
    autoplay: 1,
    animationDuration: 3000,
    animationTimingFunc: "ease-in-out",
    perView: 5.2,
    type: "carousel",
    bullet: false,
    gap: 80,
    hoverpause: false,
    breakpoints: {
      992: {
        perView: 4.2,
        gap: 30
      },
      450: {
        perView: 3.2,
        gap: 30
      }
    }
  };
};
